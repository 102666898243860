// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class IdentityApiMethodsCls {
    async identityList(wh, {limit, offset, page, sort, filters} = {}) {
      // Все Identity компании, к которой принадлежит пользователь
      // filters: created_at, updated_at, company, user, email, role, exact_position_name,
      //          is_active, is_deleted, is_managed
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('identityList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async identityCreate(wh, data) {
      // data: IdentityCreate
      // Создание Identity
      console.log('identityCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async identityCreateexCreate(wh, data) {
      // data: UserIdentityCreate
      // Одновременное создание User и Identity
      console.log('identityCreateexCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.createex.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async identityPasswordCreate(wh, data) {
      // data: IdentityChangePassword
      // Изменение пароля заказчика
      console.log('identityPasswordCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.password.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async identityRetrieve(wh, id) {
      // Получение модели Identity
      console.log('identityRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('account__identity', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'account__identity', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async identityUpdate(wh, id, data) {
      // data: IdentityUpdate
      // Обновление модели Identity
      console.log('identityUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async identityDelete(wh, id) {
      // Удаление Identity
      console.log('identityDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async identityActivatePartial(wh, id, data) {
      // data: IdentityActivate
      // Изменение поля is_active для личности (Identity)
      console.log('identityActivatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.activate.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async identityOrganizationsList(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Organization для Identity
      // filters: full_title, ogrn, inn, kpp, okpo, address, postal_address, email
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('identityOrganizationsList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.organizations.list(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async identityPersonsList(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Person для Identity
      // filters: name, surname, patronymic, passport_number, fio
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('identityPersonsList')
      const response = await ctx.$services.fetch.request({config: endpoints.account.identity.persons.list(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
  }

  ctx.$services.identityApi = new IdentityApiMethodsCls()
}

