// import { useStore } from 'vuex'
// const vuexStore = useStore()
// import {useRouter} from "vue-router";

function _2digit(num) {
  if (num < 10) {
    return '0' + num
  }
  return String(num)
}

export default ctx => {
  class DateService  {
    splitDate(date) {
      if (typeof(date) === 'string' || date instanceof String) {
        date = new Date(date)
      }
      if (! (date instanceof Date)) {
        return {
          empty: true,
          year: '0000',
          month: '01',
          day: '01',
          hours: '00',
          minutes: '00',
        }
      }
      return {
        empty: false,
        year: date.getYear() + 1900,
        month: _2digit(date.getMonth() + 1),
        day: _2digit(date.getDate()),
        hours: _2digit(date.getHours()),
        minutes: _2digit(date.getMinutes())
      }
    }
    formatDate(date, def=''){
      date = this.splitDate(date)
      if (date.empty) {
        return def
      }
      return `${date.day}.${date.month}.${date.year}`
    }
    formatTime(date, def=''){
      date = this.splitDate(date)
      if (date.empty) {
        return def
      }
      return `${date.hours}:${date.minutes}`
    }
    formatDateTime(date, def=''){
      date = this.splitDate(date)
      if (date.empty) {
        return def
      }
      return `${date.day}.${date.month}.${date.year}<sup>${date.hours}:${date.minutes}</sup>`
    }
  }
  ctx.$services.date = new DateService()
}
