// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class DocumentTemplateApiMethodsCls {
    async documentTemplateList(wh, {limit, offset, page, filters} = {}) {
      // Получение списка шаблонов документов
      // filters: company, type, name, is_default, ordering
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('documentTemplateList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async documentTemplateCreate(wh, data) {
      // data: DocumentTemplate
      // Создание шаблона документа
      console.log('documentTemplateCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async documentTemplateRetrieve(wh, id) {
      // Получение конкретного шаблона документа
      console.log('documentTemplateRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('legal__documenttemplate', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'legal__documenttemplate', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async documentTemplateUpdate(wh, id, data) {
      // data: DocumentTemplate
      // Изменение шаблона документа
      console.log('documentTemplateUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async documentTemplateDelete(wh, id) {
      // Удаление шаблона документа
      console.log('documentTemplateDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplate.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async documentTemplateTypeList(wh, {limit, offset, page} = {}) {
      // Получение списка типов шаблонов документов
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('documentTemplateTypeList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplateType.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async documentTemplateTypeDefaultList(wh, type) {
      // Получение шаблона по умолчанию для определённого типа шаблонов документов
      console.log('documentTemplateTypeDefaultList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.documentTemplateType.default.list(type)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.document_templateApi = new DocumentTemplateApiMethodsCls()
}

