// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ProgramApiMethodsCls {
    async hotelProgramsRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка программ пребывания для отеля
      // filters: created_at, updated_at, title, handle, description, ages, categories, meals,
      //          shifts, gender, min_duration, max_duration, is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelProgramsRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('refbook__program', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'refbook__program', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.programs.retrieve(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async programCreate(wh, data) {
      // data: Program
      // Создание Программы пребывания
      console.log('programCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.program.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async programRetrieve(wh, id) {
      // Получение Программы пребывания
      console.log('programRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('refbook__program', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'refbook__program', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.program.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async programUpdate(wh, id, data) {
      // data: Program
      // Обновление Программы пребывания
      console.log('programUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.program.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async programDelete(wh, id) {
      // Удаление Программы пребывания
      console.log('programDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.program.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async programPartial(wh, id, data) {
      // data: ProgramIsActive
      // Изменение поля is_active Программы пребывания
      console.log('programPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.program.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.programApi = new ProgramApiMethodsCls()
}

