// import { useStore } from 'vuex'
// const vuexStore = useStore()
// import {useRouter} from "vue-router";

export default ctx => {
  class LocaleService  {

    getLocale() {
      return {
        locale: 'ru-RU',
      }
    }
  }
  console.log('LocaleService created')
  ctx.$services.locale = new LocaleService()
}
