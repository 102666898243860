// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class GenericApiMethodsCls {
    async autocompletePut(wh, data) {
      // data: Autocomplete
      // поиск
      console.log('autocompletePut')
      const response = await ctx.$services.fetch.request({config: endpoints.core.autocomplete.put(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async genericGetRetrieve(wh, data_type, id) {
      // Получение произвольного объекта
      console.log('genericGetRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.core.genericGet.retrieve(data_type, id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async genericTitleRetrieve(wh, data_type, id) {
      // Получение заголовка произвольного объекта
      console.log('genericTitleRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.core.genericTitle.retrieve(data_type, id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async searchList(wh, {filters} = {}) {
      // Поиск туров по переданным параметрам
      // filters: phone, email, fio
      const params = { ...filters }
      console.log('searchList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.search.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async typesList(wh, {limit, offset, page} = {}) {
      // Получение списка content types
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('typesList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.types.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
  }

  ctx.$services.genericApi = new GenericApiMethodsCls()
}

