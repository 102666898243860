// Этот файл сгенерирован автоматически. Не надо его редактировать.

export default {
    account: {
        authinfo: {
            // Получение информации о текущей аутентификации
            retrieve: () => ({url:`api/account/authinfo/`, method:'GET'})
        },
        change: {
            // Смена роли
            create: () => ({url:`api/account/change/`, method:'POST'})
        },
        company: {
            // Получение списка компаний
            list: (params = {}) => ({url:`api/account/company/`, method:'GET', params}),
            // Создание компании
            create: () => ({url:`api/account/company/`, method:'POST'}),
            createex: {
                // Создание Компании и юрлица
                create: () => ({url:`api/account/company/createex/`, method:'POST'})
            },
            // Получение компании
            retrieve: (id) => ({url:`api/account/company/${id}/`, method:'GET'}),
            // Обновление модели компании
            update: (id) => ({url:`api/account/company/${id}/`, method:'PUT'}),
            // Удаление компании
            delete: (id) => ({url:`api/account/company/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для компании (Company)
                partial: (id) => ({url:`api/account/company/${id}/activate/`, method:'PATCH'})
            },
            identities: {
                // Все доступные Identities компании, за исключением CUSTOMER
                list: (id, params = {}) => ({url:`api/account/company/${id}/identities/`, method:'GET', params})
            },
            phones: {
                // Все внутренние телефоны компании
                list: (id, params = {}) => ({url:`api/account/company/${id}/phones/`, method:'GET', params})
            },
            roles: {
                // Список ролей в компании
                list: (id) => ({url:`api/account/company/${id}/roles/`, method:'GET'})
            }
        },
        dev: {
            error: {
                // Получение ответа с заданной ошибкой
                retrieve: (code) => ({url:`api/account/dev/error/${code}/`, method:'GET'})
            }
        },
        identity: {
            // Все Identity компании, к которой принадлежит пользователь
            list: (params = {}) => ({url:`api/account/identity/`, method:'GET', params}),
            // Создание Identity
            create: () => ({url:`api/account/identity/`, method:'POST'}),
            createex: {
                // Одновременное создание User и Identity
                create: () => ({url:`api/account/identity/createex/`, method:'POST'})
            },
            password: {
                // Изменение пароля заказчика
                create: () => ({url:`api/account/identity/password/`, method:'POST'})
            },
            // Получение модели Identity
            retrieve: (id) => ({url:`api/account/identity/${id}/`, method:'GET'}),
            // Обновление модели Identity
            update: (id) => ({url:`api/account/identity/${id}/`, method:'PUT'}),
            // Удаление Identity
            delete: (id) => ({url:`api/account/identity/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для личности (Identity)
                partial: (id) => ({url:`api/account/identity/${id}/activate/`, method:'PATCH'})
            },
            organizations: {
                // Получение списка Organization для Identity
                list: (id, params = {}) => ({url:`api/account/identity/${id}/organizations/`, method:'GET', params})
            },
            persons: {
                // Получение списка Person для Identity
                list: (id, params = {}) => ({url:`api/account/identity/${id}/persons/`, method:'GET', params})
            }
        },
        invite: {
            // Создать инвайт
            create: () => ({url:`api/account/invite/`, method:'POST'})
        },
        invites: {
            // Список инвайтов
            list: () => ({url:`api/account/invites/`, method:'GET'})
        },
        logout: {
            // Выход из системы для временных токенов
            delete: () => ({url:`api/account/logout/`, method:'DELETE'})
        },
        myroles: {
            // Получение списка доступных мне ролей, за исключением CUSTOMER
            list: (params = {}) => ({url:`api/account/myroles/`, method:'GET', params})
        },
        user: {
            // Получение списка User
            list: (params = {}) => ({url:`api/account/user/`, method:'GET', params}),
            // Создание User
            create: () => ({url:`api/account/user/`, method:'POST'}),
            password: {
                // Изменение пароля пользователем
                create: () => ({url:`api/account/user/password/`, method:'POST'}),
                // Изменение пароля пользователя, руководителем компании, если пользователь is_managed
                partial: (id) => ({url:`api/account/user/${id}/password/`, method:'PATCH'})
            },
            // Получение User
            retrieve: (id) => ({url:`api/account/user/${id}/`, method:'GET'}),
            // Обновление модели User
            update: (id) => ({url:`api/account/user/${id}/`, method:'PUT'}),
            // Удаление User
            delete: (id) => ({url:`api/account/user/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для User
                partial: (id) => ({url:`api/account/user/${id}/activate/`, method:'PATCH'})
            }
        }
    },
    auth: {
        accept: {
            data: {
                // Принятие инвайта, стадия 3
                create: () => ({url:`api/auth/accept/data/`, method:'POST'})
            },
            pincode: {
                // Принятие инвайта, стадия 2
                create: () => ({url:`api/auth/accept/pincode/`, method:'POST'})
            },
            start: {
                // Принятие инвайта, стадия 1
                create: () => ({url:`api/auth/accept/start/`, method:'POST'})
            }
        },
        clone: {
            // Клонирование токена
            create: () => ({url:`api/auth/clone/`, method:'POST'})
        },
        customer: {
            // Аутентификация заказчика
            create: () => ({url:`api/auth/customer/`, method:'POST'})
        },
        customerRegister: {
            data: {
                // Регистрация заказчика, стадия 3
                create: () => ({url:`api/auth/customer_register/data/`, method:'POST'})
            },
            pincode: {
                // Регистрация заказчика, стадия 2
                create: () => ({url:`api/auth/customer_register/pincode/`, method:'POST'})
            },
            start: {
                // Регистрация заказчика, стадия 1
                create: () => ({url:`api/auth/customer_register/start/`, method:'POST'})
            }
        },
        personal: {
            // Аутентификация пользователя
            create: () => ({url:`api/auth/personal/`, method:'POST'})
        },
        pincode: {
            pincode: {
                // Авторизация по пинкоду, стадия 2
                create: () => ({url:`api/auth/pincode/pincode/`, method:'POST'})
            },
            start: {
                // Авторизация по пинкоду, стадия 1
                create: () => ({url:`api/auth/pincode/start/`, method:'POST'})
            }
        },
        refresh: {
            // Перегенерация токена
            create: () => ({url:`api/auth/refresh/`, method:'POST'})
        },
        register: {
            data: {
                // Регистрация, стадия 3
                create: () => ({url:`api/auth/register/data/`, method:'POST'})
            },
            pincode: {
                // Регистрация, стадия 2
                create: () => ({url:`api/auth/register/pincode/`, method:'POST'})
            },
            start: {
                // Регистрация, стадия 1
                create: () => ({url:`api/auth/register/start/`, method:'POST'})
            }
        },
        restore: {
            pincode: {
                // Запрос восстановления пароля, стадия 2
                create: () => ({url:`api/auth/restore/pincode/`, method:'POST'})
            },
            start: {
                // Запрос восстановления пароля, стадия 1
                create: () => ({url:`api/auth/restore/start/`, method:'POST'})
            }
        },
        token: {
            telephony: {
                cmd: {
                    list: (cmd, token) => ({url:`api/auth/${token}/telephony/${cmd}/`, method:'GET'})
                }
            }
        }
    },
    core: {
        agentFee: {
            // Получение списка док-тов начисления вознаграждений
            list: (params = {}) => ({url:`api/core/agent_fee/`, method:'GET', params}),
            // Создание док-та начисления вознаграждений
            create: () => ({url:`api/core/agent_fee/`, method:'POST'}),
            // Получение док-та начисления вознаграждений
            retrieve: (id) => ({url:`api/core/agent_fee/${id}/`, method:'GET'}),
            // Обновление док-та начисления вознаграждений
            update: (id) => ({url:`api/core/agent_fee/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/core/agent_fee/${id}/`, method:'PATCH'}),
            approve: {
                // Утверждение док-та начисления вознаграждений
                partial: (id) => ({url:`api/core/agent_fee/${id}/approve/`, method:'PATCH'})
            },
            remove: {
                // Удаление док-та начисления вознаграждений
                partial: (id) => ({url:`api/core/agent_fee/${id}/remove/`, method:'PATCH'})
            }
        },
        autocomplete: {
            // поиск
            put: () => ({url:`api/core/autocomplete/`, method:'PUT'})
        },
        booking: {
            list: (params = {}) => ({url:`api/core/booking/`, method:'GET', params}),
            // Создание брони (Booking)
            create: () => ({url:`api/core/booking/`, method:'POST'}),
            retrieve: (id) => ({url:`api/core/booking/${id}/`, method:'GET'}),
            addPerson: {
                // Добавление персоны в бронь
                partial: (id) => ({url:`api/core/booking/${id}/add_person/`, method:'PATCH'})
            },
            addTourist: {
                // Создание Tourist для Booking
                post: (id) => ({url:`api/core/booking/${id}/add_tourist/`, method:'POST'})
            },
            bookingApi: {
                partial: (id) => ({url:`api/core/booking/${id}/booking_api/`, method:'PATCH'})
            }
        },
        buyer: {
            // Получение списка Buyer
            list: (params = {}) => ({url:`api/core/buyer/`, method:'GET', params}),
            // Создание Buyer
            create: () => ({url:`api/core/buyer/`, method:'POST'}),
            // Получение Buyer
            retrieve: (id) => ({url:`api/core/buyer/${id}/`, method:'GET'}),
            // Обновление Buyer
            update: (id) => ({url:`api/core/buyer/${id}/`, method:'PUT'}),
            crLimit: {
                // Изменение cr_limit у Buyer
                partial: (id) => ({url:`api/core/buyer/${id}/cr_limit/`, method:'PATCH'})
            },
            person: {
                // Изменение person и(или) organization у Buyer
                partial: (id) => ({url:`api/core/buyer/${id}/person/`, method:'PATCH'})
            },
            responsible: {
                // Изменение responsible у Buyer
                partial: (id) => ({url:`api/core/buyer/${id}/responsible/`, method:'PATCH'})
            }
        },
        comment: {
            // Получение списка комментариев
            list: (params = {}) => ({url:`api/core/comment/`, method:'GET', params}),
            // Создание комментария
            create: () => ({url:`api/core/comment/`, method:'POST'}),
            listForObject: {
                // Получение списка комментариев для объекта
                list: (data_type, id, params = {}) => ({url:`api/core/comment/${data_type}/${id}/`, method:'GET', params})
            },
            // Получение конкретного комментария
            retrieve: (id) => ({url:`api/core/comment/${id}/`, method:'GET'}),
            // Изменение комментария
            update: (id) => ({url:`api/core/comment/${id}/`, method:'PUT'}),
            // Удаление комментария
            delete: (id) => ({url:`api/core/comment/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для комментария
                partial: (id) => ({url:`api/core/comment/${id}/activate/`, method:'PATCH'})
            }
        },
        fms: {
            // Получение списка Fms (фмс карточка)
            list: (params = {}) => ({url:`api/core/fms/`, method:'GET', params}),
            // Создание Fms (фмс карточка)
            create: () => ({url:`api/core/fms/`, method:'POST'}),
            // Получение Fms (фмс карточка)
            retrieve: (id) => ({url:`api/core/fms/${id}/`, method:'GET'}),
            // Обновление Fms (фмс карточка)
            update: (id) => ({url:`api/core/fms/${id}/`, method:'PUT'}),
            // Удаление Fms (фмс карточка)
            delete: (id) => ({url:`api/core/fms/${id}/`, method:'DELETE'})
        },
        genericGet: {
            // Получение произвольного объекта
            retrieve: (data_type, id) => ({url:`api/core/generic_get/${data_type}/${id}/`, method:'GET'})
        },
        genericTitle: {
            // Получение заголовка произвольного объекта
            retrieve: (data_type, id) => ({url:`api/core/generic_title/${data_type}/${id}/`, method:'GET'})
        },
        lead: {
            // Получение списка Заявок
            list: (params = {}) => ({url:`api/core/lead/`, method:'GET', params}),
            // Создание Заявки
            create: () => ({url:`api/core/lead/`, method:'POST'}),
            // Получение Заявки
            retrieve: (id) => ({url:`api/core/lead/${id}/`, method:'GET'}),
            // Обновление Заявки
            update: (id) => ({url:`api/core/lead/${id}/`, method:'PUT'})
        },
        organization: {
            // Получение списка Organization
            list: (params = {}) => ({url:`api/core/organization/`, method:'GET', params}),
            // Создание Organization
            create: () => ({url:`api/core/organization/`, method:'POST'}),
            // Получение Organization
            retrieve: (id) => ({url:`api/core/organization/${id}/`, method:'GET'}),
            // Обновление Organization
            update: (id) => ({url:`api/core/organization/${id}/`, method:'PUT'}),
            // Удаление Organization
            delete: (id) => ({url:`api/core/organization/${id}/`, method:'DELETE'}),
            responsible: {
                // Изменение responsible у Organization
                partial: (id) => ({url:`api/core/organization/${id}/responsible/`, method:'PATCH'})
            }
        },
        person: {
            // Получение списка Person
            list: (params = {}) => ({url:`api/core/person/`, method:'GET', params}),
            // Создание Person
            create: () => ({url:`api/core/person/`, method:'POST'}),
            // Получение Person
            retrieve: (id) => ({url:`api/core/person/${id}/`, method:'GET'}),
            // Обновление Person
            update: (id) => ({url:`api/core/person/${id}/`, method:'PUT'}),
            // Удаление Person
            delete: (id) => ({url:`api/core/person/${id}/`, method:'DELETE'}),
            responsible: {
                // Изменение responsible у Person
                partial: (id) => ({url:`api/core/person/${id}/responsible/`, method:'PATCH'})
            }
        },
        search: {
            // Поиск туров по переданным параметрам
            list: (params = {}) => ({url:`api/core/search/`, method:'GET', params})
        },
        tour: {
            // Создание тура (Tour)
            create: () => ({url:`api/core/tour/`, method:'POST'}),
            addPerson: {
                // Добавление персоны (Person)
                partial: (id) => ({url:`api/core/tour/${id}/add_person/`, method:'PATCH'})
            }
        },
        tourist: {
            // Обновление Tourist
            update: (id) => ({url:`api/core/tourist/${id}/`, method:'PUT'}),
            // Удаление Tourist
            delete: (id) => ({url:`api/core/tourist/${id}/`, method:'DELETE'})
        },
        types: {
            // Получение списка content types
            list: (params = {}) => ({url:`api/core/types/`, method:'GET', params})
        }
    },
    finance: {
        agency: {
            balance: {
                buyer: {
                    buyerId: {
                        latest: {
                            // Получение текущих балансов заказчика агентства
                            list: (buyer_id, params = {}) => ({url:`api/finance/agency/balance/buyer/${buyer_id}/latest/`, method:'GET', params})
                        },
                        date: {
                            // Получение текущих балансов заказчика агентства на дату
                            list: (buyer_id, date, params = {}) => ({url:`api/finance/agency/balance/buyer/${buyer_id}/${date}/`, method:'GET', params})
                        }
                    }
                },
                latest: {
                    // Получение текущих балансов всех заказчиков агентства
                    list: (params = {}) => ({url:`api/finance/agency/balance/latest/`, method:'GET', params})
                },
                date: {
                    // Получение текущих балансов всех заказчиков агентства на дату
                    list: (date, params = {}) => ({url:`api/finance/agency/balance/${date}/`, method:'GET', params})
                }
            },
            entity: {
                payments: {
                    // Получение списка платежей для юр. лица
                    list: (id, params = {}) => ({url:`api/finance/agency/entity/${id}/payments/`, method:'GET', params})
                }
            },
            entry: {
                // Получение списка проводок
                list: (params = {}) => ({url:`api/finance/agency/entry/`, method:'GET', params}),
                create: {
                    // Создание ручной проводки
                    create: () => ({url:`api/finance/agency/entry/create/`, method:'POST'})
                },
                // Получение конкретной проводки
                retrieve: (id) => ({url:`api/finance/agency/entry/${id}/`, method:'GET'})
            },
            payment: {
                create: {
                    // Создание платежа агентству
                    create: () => ({url:`api/finance/agency/payment/create/`, method:'POST'})
                },
                import: {
                    // Импорт файла платежей агентству
                    create: () => ({url:`api/finance/agency/payment/import/`, method:'POST'}),
                    accept: {
                        // Принятие платежей агентству
                        partial: (id) => ({url:`api/finance/agency/payment/import/${id}/accept/`, method:'PATCH'})
                    }
                },
                // Получение конкретного платежа агентству
                retrieve: (id) => ({url:`api/finance/agency/payment/${id}/`, method:'GET'}),
                accept: {
                    // Принять платеж агентству
                    partial: (id) => ({url:`api/finance/agency/payment/${id}/accept/`, method:'PATCH'})
                },
                annul: {
                    // Аннулировать платеж агентству
                    partial: (id) => ({url:`api/finance/agency/payment/${id}/annul/`, method:'PATCH'})
                },
                cancel: {
                    // Отменить платеж агентству
                    partial: (id) => ({url:`api/finance/agency/payment/${id}/cancel/`, method:'PATCH'})
                },
                hold: {
                    // Провести платеж агентству
                    partial: (id) => ({url:`api/finance/agency/payment/${id}/hold/`, method:'PATCH'})
                },
                restore: {
                    // Восстановить платеж агентству
                    partial: (id) => ({url:`api/finance/agency/payment/${id}/restore/`, method:'PATCH'})
                }
            }
        },
        finance: {
            balance: {
                // Получение текущих балансов всех контрагентов системы
                list: (params = {}) => ({url:`api/finance/finance/balance/`, method:'GET', params})
            },
            balanceForDate: {
                date: {
                    // Получение балансов всех контрагентов системы на дату
                    list: (date, params = {}) => ({url:`api/finance/finance/balance_for_date/${date}/`, method:'GET', params})
                }
            },
            contractorBalance: {
                contractorEntityId: {
                    // Получение текущего баланса контрагента системы
                    list: (contractor_entity_id, params = {}) => ({url:`api/finance/finance/contractor_balance/${contractor_entity_id}/`, method:'GET', params})
                }
            },
            contractorBalanceForDate: {
                contractorEntityId: {
                    date: {
                        // Получение баланса контрагента системы на дату
                        list: (contractor_entity_id, date, params = {}) => ({url:`api/finance/finance/contractor_balance_for_date/${contractor_entity_id}/${date}/`, method:'GET', params})
                    }
                }
            }
        }
    },
    hms: {
        flag: {
            // Удаление RoomFlag
            delete: (id) => ({url:`api/hms/flag/${id}/`, method:'DELETE'})
        },
        guest: {
            // Обновление Guest
            update: (id) => ({url:`api/hms/guest/${id}/`, method:'PUT'}),
            // Удаление Guest
            delete: (id) => ({url:`api/hms/guest/${id}/`, method:'DELETE'})
        },
        hotel: {
            cross: {
                availability: {
                    // Данные для шахматки по доступности для текущего периода (не реализовано)
                    retrieve: (id) => ({url:`api/hms/hotel/${id}/cross/availability/`, method:'GET'}),
                    // Данные для шахматки по доступности для заданного периода (не реализовано)
                    update: (id) => ({url:`api/hms/hotel/${id}/cross/availability/`, method:'PUT'})
                },
                prices: {
                    // Данные для шахматки по ценам для текущего периода (не реализовано)
                    retrieve: (id) => ({url:`api/hms/hotel/${id}/cross/prices/`, method:'GET'}),
                    // Данные для шахматки по ценам для заданного периода (не реализовано)
                    update: (id) => ({url:`api/hms/hotel/${id}/cross/prices/`, method:'PUT'})
                },
                rooms: {
                    // Данные для шахматки по номерам для текущего периода
                    retrieve: (id) => ({url:`api/hms/hotel/${id}/cross/rooms/`, method:'GET'}),
                    // Данные для шахматки по номерам для заданного периода
                    update: (id) => ({url:`api/hms/hotel/${id}/cross/rooms/`, method:'PUT'})
                }
            },
            occupation: {
                // Получение списка Occupation для отеля
                retrieve: (id, params = {}) => ({url:`api/hms/hotel/${id}/occupation/`, method:'GET', params})
            },
            period: {
                // Получение списка периодов для отеля
                retrieve: (id, params = {}) => ({url:`api/hms/hotel/${id}/period/`, method:'GET', params}),
                // Создание периода для отеля
                post: (id) => ({url:`api/hms/hotel/${id}/period/`, method:'POST'})
            },
            reservation: {
                // Получение списка Reservation для отеля
                retrieve: (id, params = {}) => ({url:`api/hms/hotel/${id}/reservation/`, method:'GET', params})
            }
        },
        occupation: {
            // Создание Occupation
            create: () => ({url:`api/hms/occupation/`, method:'POST'}),
            // Получение Occupation
            retrieve: (id) => ({url:`api/hms/occupation/${id}/`, method:'GET'}),
            // Обновление модели Occupation
            update: (id) => ({url:`api/hms/occupation/${id}/`, method:'PUT'}),
            // Обновление полей Occupation
            partial: (id) => ({url:`api/hms/occupation/${id}/`, method:'PATCH'}),
            // Удаление Occupation, только для статуса Draft
            delete: (id) => ({url:`api/hms/occupation/${id}/`, method:'DELETE'}),
            addResident: {
                // Создание Resident для occupation
                post: (id) => ({url:`api/hms/occupation/${id}/add_resident/`, method:'POST'})
            },
            cancel: {
                // Отмена. Переводит в статус CANCELED
                partial: (id) => ({url:`api/hms/occupation/${id}/cancel/`, method:'PATCH'})
            },
            checkIn: {
                // Переводит в статус CHECK_IN
                partial: (id) => ({url:`api/hms/occupation/${id}/check_in/`, method:'PATCH'})
            },
            checkOut: {
                // Переводит в статус CHECK_OUT
                partial: (id) => ({url:`api/hms/occupation/${id}/check_out/`, method:'PATCH'})
            },
            move: {
                // Переезд в другой номер
                partial: (id) => ({url:`api/hms/occupation/${id}/move/`, method:'PATCH'})
            },
            prolong: {
                // Продление. Меняет плановое время, если есть свободные места
                partial: (id) => ({url:`api/hms/occupation/${id}/prolong/`, method:'PATCH'})
            }
        },
        period: {
            // Получение периода отеля
            retrieve: (id) => ({url:`api/hms/period/${id}/`, method:'GET'}),
            // Обновление дат периода отеля
            update: (id) => ({url:`api/hms/period/${id}/`, method:'PUT'}),
            // Удаление периода отеля
            delete: (id) => ({url:`api/hms/period/${id}/`, method:'DELETE'}),
            activate: {
                // Обновление поля is_active периода (Period)
                partial: (id) => ({url:`api/hms/period/${id}/activate/`, method:'PATCH'})
            }
        },
        reservation: {
            // Создание Reservation
            create: () => ({url:`api/hms/reservation/`, method:'POST'}),
            // Получение Reservation
            retrieve: (id) => ({url:`api/hms/reservation/${id}/`, method:'GET'}),
            // Обновление модели Reservation
            update: (id) => ({url:`api/hms/reservation/${id}/`, method:'PUT'}),
            // Обновление полей Reservation
            partial: (id) => ({url:`api/hms/reservation/${id}/`, method:'PATCH'}),
            // Удаление Reservation, только для статуса Draft
            delete: (id) => ({url:`api/hms/reservation/${id}/`, method:'DELETE'}),
            addGuest: {
                // Создание Guest для reservation
                post: (id) => ({url:`api/hms/reservation/${id}/add_guest/`, method:'POST'})
            },
            book: {
                // Запрос на бронирование
                partial: (id) => ({url:`api/hms/reservation/${id}/book/`, method:'PATCH'})
            },
            cancel: {
                // Переводит в статус CANCELLED. Для любого статуса, кроме DRAFT
                partial: (id) => ({url:`api/hms/reservation/${id}/cancel/`, method:'PATCH'})
            },
            confirm: {
                // Переводит статус ON_REQUEST в BOOKED
                partial: (id) => ({url:`api/hms/reservation/${id}/confirm/`, method:'PATCH'})
            }
        },
        resident: {
            // Обновление Resident
            update: (id) => ({url:`api/hms/resident/${id}/`, method:'PUT'}),
            // Удаление Resident
            delete: (id) => ({url:`api/hms/resident/${id}/`, method:'DELETE'})
        },
        room: {
            // Получение списка Room
            list: (params = {}) => ({url:`api/hms/room/`, method:'GET', params}),
            // Создание Room
            create: () => ({url:`api/hms/room/`, method:'POST'}),
            // Получение Room
            retrieve: (id) => ({url:`api/hms/room/${id}/`, method:'GET'}),
            // Обновление модели Room
            update: (id) => ({url:`api/hms/room/${id}/`, method:'PUT'}),
            flag: {
                // Создание RoomFlag
                post: (id) => ({url:`api/hms/room/${id}/flag/`, method:'POST'})
            }
        }
    },
    legal: {
        bankAccount: {
            // Получение списка BankAccount
            list: (params = {}) => ({url:`api/legal/bank_account/`, method:'GET', params}),
            // Создание BankAccount
            create: () => ({url:`api/legal/bank_account/`, method:'POST'}),
            // Получение модели BankAccount
            retrieve: (id) => ({url:`api/legal/bank_account/${id}/`, method:'GET'}),
            partial: (id) => ({url:`api/legal/bank_account/${id}/`, method:'PATCH'}),
            // Удаление BankAccount
            delete: (id) => ({url:`api/legal/bank_account/${id}/`, method:'DELETE'}),
            setMain: {
                // Сделать счёт основным для юр. лица
                partial: (id) => ({url:`api/legal/bank_account/${id}/set_main/`, method:'PATCH'})
            }
        },
        commission: {
            // Получить список активных планов комиссий
            list: (params = {}) => ({url:`api/legal/commission/`, method:'GET', params}),
            // Создать план комиссий
            create: () => ({url:`api/legal/commission/`, method:'POST'}),
            all: {
                // Получить список всех планов комиссий
                list: (params = {}) => ({url:`api/legal/commission/all/`, method:'GET', params})
            },
            // Получить конкретный план комиссий
            retrieve: (id) => ({url:`api/legal/commission/${id}/`, method:'GET'}),
            // Изменить план комиссий
            update: (id) => ({url:`api/legal/commission/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/legal/commission/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/legal/commission/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для AgencyCommissionPlan
                partial: (id) => ({url:`api/legal/commission/${id}/activate/`, method:'PATCH'})
            }
        },
        contract: {
            // Получить список договоров
            list: (params = {}) => ({url:`api/legal/contract/`, method:'GET', params}),
            agency: {
                // Список договоров системы с агентством
                list: (params = {}) => ({url:`api/legal/contract/agency/`, method:'GET', params}),
                // Создать договор системы с агентством
                create: () => ({url:`api/legal/contract/agency/`, method:'POST'}),
                // Получить договор системы с агентством
                retrieve: (id) => ({url:`api/legal/contract/agency/${id}/`, method:'GET'}),
                // Изменить договор системы с агентством
                update: (id) => ({url:`api/legal/contract/agency/${id}/`, method:'PUT'}),
                delete: (id) => ({url:`api/legal/contract/agency/${id}/`, method:'DELETE'})
            },
            hotelier: {
                // Список договоров системы с отелем
                list: (params = {}) => ({url:`api/legal/contract/hotelier/`, method:'GET', params}),
                // Создать договор системы с отелем
                create: () => ({url:`api/legal/contract/hotelier/`, method:'POST'}),
                // Получить договор системы с отелем
                retrieve: (id) => ({url:`api/legal/contract/hotelier/${id}/`, method:'GET'}),
                // Изменить договор системы с отелем
                update: (id) => ({url:`api/legal/contract/hotelier/${id}/`, method:'PUT'}),
                delete: (id) => ({url:`api/legal/contract/hotelier/${id}/`, method:'DELETE'})
            },
            // Получить конкретный договор без данных соответствующего ему типа
            retrieve: (id) => ({url:`api/legal/contract/${id}/`, method:'GET'}),
            approve: {
                // Одобрить договор
                partial: (id) => ({url:`api/legal/contract/${id}/approve/`, method:'PATCH'})
            },
            originalSend: {
                // Выслать оригинал договора
                partial: (id) => ({url:`api/legal/contract/${id}/original_send/`, method:'PATCH'})
            },
            resume: {
                // Возобновить договор
                partial: (id) => ({url:`api/legal/contract/${id}/resume/`, method:'PATCH'})
            },
            sign: {
                // Подписать договор
                partial: (id) => ({url:`api/legal/contract/${id}/sign/`, method:'PATCH'})
            },
            terminate: {
                // Расторгнуть договор
                partial: (id) => ({url:`api/legal/contract/${id}/terminate/`, method:'PATCH'})
            }
        },
        documentTemplate: {
            // Получение списка шаблонов документов
            list: (params = {}) => ({url:`api/legal/document_template/`, method:'GET', params}),
            // Создание шаблона документа
            create: () => ({url:`api/legal/document_template/`, method:'POST'}),
            // Получение конкретного шаблона документа
            retrieve: (id) => ({url:`api/legal/document_template/${id}/`, method:'GET'}),
            // Изменение шаблона документа
            update: (id) => ({url:`api/legal/document_template/${id}/`, method:'PUT'}),
            // Удаление шаблона документа
            delete: (id) => ({url:`api/legal/document_template/${id}/`, method:'DELETE'})
        },
        documentTemplateType: {
            // Получение списка типов шаблонов документов
            list: (params = {}) => ({url:`api/legal/document_template_type/`, method:'GET', params}),
            default: {
                // Получение шаблона по умолчанию для определённого типа шаблонов документов
                list: (type) => ({url:`api/legal/document_template_type/${type}/default/`, method:'GET'})
            }
        },
        entity: {
            // Получение списка юр лиц
            list: (params = {}) => ({url:`api/legal/entity/`, method:'GET', params}),
            // Создание юр лица
            create: () => ({url:`api/legal/entity/`, method:'POST'}),
            // Получение юр лица
            retrieve: (id) => ({url:`api/legal/entity/${id}/`, method:'GET'}),
            // Обновление модели юр лица
            update: (id) => ({url:`api/legal/entity/${id}/`, method:'PUT'}),
            // Удаление юр лица
            delete: (id) => ({url:`api/legal/entity/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для юрлица (Entity)
                partial: (id) => ({url:`api/legal/entity/${id}/activate/`, method:'PATCH'})
            },
            phones: {
                // Все внутренние телефоны юр лица
                retrieve: (id, params = {}) => ({url:`api/legal/entity/${id}/phones/`, method:'GET', params})
            }
        }
    },
    media: {
        file: {
            // Получение файлов
            put: () => ({url:`api/media/file/`, method:'PUT'}),
            // Удаление файла
            delete: () => ({url:`api/media/file/`, method:'DELETE'}),
            upload: {
                // Загрузка файла
                create: () => ({url:`api/media/file/upload/`, method:'POST'})
            },
            listForObject: {
                // Получение всех файлов объекта
                list: (data_type, id) => ({url:`api/media/file/${data_type}/${id}/`, method:'GET'})
            }
        },
        image: {
            // Получение изображений
            put: () => ({url:`api/media/image/`, method:'PUT'}),
            // Удаление изображения
            delete: () => ({url:`api/media/image/`, method:'DELETE'}),
            upload: {
                // Загрузка изображения
                create: () => ({url:`api/media/image/upload/`, method:'POST'})
            },
            listForObject: {
                // Получение всех изображений объекта
                list: (data_type, id) => ({url:`api/media/image/${data_type}/${id}/`, method:'GET'})
            },
            listCategories: {
                // Список категорий в отеле
                list: (hotel_id, params = {}) => ({url:`api/refbook/hotel/${hotel_id}/categories/`, method:'GET', params})
            }
        }
    },
    message: {
        chat: {
            list: (params = {}) => ({url:`api/message/chat/`, method:'GET', params}),
            create: () => ({url:`api/message/chat/`, method:'POST'}),
            retrieve: (id) => ({url:`api/message/chat/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/message/chat/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/message/chat/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/message/chat/${id}/`, method:'DELETE'})
        },
        handleList: {
            // Получение списка транспортов
            list: () => ({url:`api/message/handle_list/`, method:'GET'})
        },
        message: {
            list: (params = {}) => ({url:`api/message/message/`, method:'GET', params}),
            create: () => ({url:`api/message/message/`, method:'POST'}),
            retrieve: (id) => ({url:`api/message/message/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/message/message/${id}/`, method:'PUT'}),
            partial: (id) => ({url:`api/message/message/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/message/message/${id}/`, method:'DELETE'})
        }
    },
    refbook: {
        age: {
            // Создание Age
            create: () => ({url:`api/refbook/age/`, method:'POST'}),
            // Обновление Age
            retrieve: (id) => ({url:`api/refbook/age/${id}/`, method:'GET'}),
            // Обновление Age
            update: (id) => ({url:`api/refbook/age/${id}/`, method:'PUT'}),
            // Удаление Age
            delete: (id) => ({url:`api/refbook/age/${id}/`, method:'DELETE'})
        },
        attribute: {
            // Получение списка Attribute
            list: (params = {}) => ({url:`api/refbook/attribute/`, method:'GET', params}),
            // Получение атрибута отеля
            retrieve: (id) => ({url:`api/refbook/attribute/${id}/`, method:'GET'}),
            // Обновление атрибута отеля
            update: (id) => ({url:`api/refbook/attribute/${id}/`, method:'PUT'}),
            // Удаление атрибута отеля (не использовать, будет удалено)
            delete: (id) => ({url:`api/refbook/attribute/${id}/`, method:'DELETE'})
        },
        bedType: {
            // Получение списка BedType
            list: (params = {}) => ({url:`api/refbook/bed_type/`, method:'GET', params})
        },
        category: {
            // Список категорий в отеле
            list: (params = {}) => ({url:`api/refbook/category/`, method:'GET', params}),
            // Создание категории
            create: () => ({url:`api/refbook/category/`, method:'POST'}),
            // Получение категории по id
            retrieve: (id) => ({url:`api/refbook/category/${id}/`, method:'GET'}),
            // Обновление модели категории
            update: (id) => ({url:`api/refbook/category/${id}/`, method:'PUT'}),
            // Удаление модели категории (не использовать, будет удалено)
            delete: (id) => ({url:`api/refbook/category/${id}/`, method:'DELETE'}),
            deep: {
                // Получить модель отеля с вложенными сущностями
                retrieve: (id, params = {}) => ({url:`api/refbook/category/${id}/deep/`, method:'GET', params})
            }
        },
        categoryType: {
            // Получение списка CategoryType
            list: (params = {}) => ({url:`api/refbook/category_type/`, method:'GET', params})
        },
        disease: {
            // Список заболеваний
            list: (params = {}) => ({url:`api/refbook/disease/`, method:'GET', params}),
            // Получение заболевания по id
            retrieve: (id) => ({url:`api/refbook/disease/${id}/`, method:'GET'})
        },
        diseaseCategory: {
            // Список категорий заболеваний
            list: (params = {}) => ({url:`api/refbook/disease_category/`, method:'GET', params}),
            // Получение категории заболеваний по id
            retrieve: (id) => ({url:`api/refbook/disease_category/${id}/`, method:'GET'})
        },
        hotel: {
            // Список отелей
            list: (params = {}) => ({url:`api/refbook/hotel/`, method:'GET', params}),
            // Создание отеля
            create: () => ({url:`api/refbook/hotel/`, method:'POST'}),
            find: {
                // Поиск отеля по региону, заболеваниям и услугам
                put: () => ({url:`api/refbook/hotel/find/`, method:'PUT'})
            },
            // Получение отеля по id
            retrieve: (id) => ({url:`api/refbook/hotel/${id}/`, method:'GET'}),
            // Изменение отеля
            update: (id) => ({url:`api/refbook/hotel/${id}/`, method:'PUT'}),
            // Удаление отеля (не использовать, будет удалено)
            delete: (id) => ({url:`api/refbook/hotel/${id}/`, method:'DELETE'}),
            activate: {
                // Изменение поля is_active для отеля
                partial: (id) => ({url:`api/refbook/hotel/${id}/activate/`, method:'PATCH'})
            },
            ages: {
                // Получение списка Age для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/ages/`, method:'GET', params})
            },
            attribute: {
                // Получение списка атрибутов отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/attribute/`, method:'GET', params}),
                // Создание атрибута отеля
                post: (id) => ({url:`api/refbook/hotel/${id}/attribute/`, method:'POST'})
            },
            deep: {
                // Получить модель отеля с вложенными сущностями
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/deep/`, method:'GET', params})
            },
            meals: {
                // Получение списка программ питания для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/meals/`, method:'GET', params})
            },
            programs: {
                // Получение списка программ пребывания для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/programs/`, method:'GET', params})
            },
            shifts: {
                // Получение списка смен для отеля
                retrieve: (id, params = {}) => ({url:`api/refbook/hotel/${id}/shifts/`, method:'GET', params})
            }
        },
        hotelService: {
            // Список услуг отеля
            list: (params = {}) => ({url:`api/refbook/hotel_service/`, method:'GET', params}),
            // Получение услуги отеля по id
            retrieve: (id) => ({url:`api/refbook/hotel_service/${id}/`, method:'GET'})
        },
        hotelServiceCategory: {
            // Список категорий услуг отеля
            list: (params = {}) => ({url:`api/refbook/hotel_service_category/`, method:'GET', params}),
            // Получение категории услуг отеля по id
            retrieve: (id) => ({url:`api/refbook/hotel_service_category/${id}/`, method:'GET'})
        },
        meal: {
            // Создание Программы питания
            create: () => ({url:`api/refbook/meal/`, method:'POST'}),
            // Обновление Программы питания
            retrieve: (id) => ({url:`api/refbook/meal/${id}/`, method:'GET'}),
            // Обновление Программы питания
            update: (id) => ({url:`api/refbook/meal/${id}/`, method:'PUT'}),
            // Изменение поля is_active Программы питания
            partial: (id) => ({url:`api/refbook/meal/${id}/`, method:'PATCH'}),
            // Удаление Программы питания
            delete: (id) => ({url:`api/refbook/meal/${id}/`, method:'DELETE'})
        },
        program: {
            // Создание Программы пребывания
            create: () => ({url:`api/refbook/program/`, method:'POST'}),
            // Получение Программы пребывания
            retrieve: (id) => ({url:`api/refbook/program/${id}/`, method:'GET'}),
            // Обновление Программы пребывания
            update: (id) => ({url:`api/refbook/program/${id}/`, method:'PUT'}),
            // Изменение поля is_active Программы пребывания
            partial: (id) => ({url:`api/refbook/program/${id}/`, method:'PATCH'}),
            // Удаление Программы пребывания
            delete: (id) => ({url:`api/refbook/program/${id}/`, method:'DELETE'})
        },
        roomService: {
            // Список услуг номера
            list: (params = {}) => ({url:`api/refbook/room_service/`, method:'GET', params}),
            // Получение услуги номера по id
            retrieve: (id) => ({url:`api/refbook/room_service/${id}/`, method:'GET'})
        },
        shift: {
            // Создание Смены
            create: () => ({url:`api/refbook/shift/`, method:'POST'}),
            // Обновление Смены
            retrieve: (id) => ({url:`api/refbook/shift/${id}/`, method:'GET'}),
            // Обновление Смены
            update: (id) => ({url:`api/refbook/shift/${id}/`, method:'PUT'}),
            // Удаление Смены
            delete: (id) => ({url:`api/refbook/shift/${id}/`, method:'DELETE'})
        },
        socialGroup: {
            // Получение списка SocialGroups
            list: (params = {}) => ({url:`api/refbook/social_group/`, method:'GET', params})
        },
        viewType: {
            // Получение списка ViewType
            list: (params = {}) => ({url:`api/refbook/view_type/`, method:'GET', params})
        }
    },
    scripts: {
        exit: {
            list: (params = {}) => ({url:`api/scripts/exit/`, method:'GET', params}),
            create: () => ({url:`api/scripts/exit/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/exit/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/exit/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/exit/${id}/`, method:'DELETE'})
        },
        question: {
            list: (params = {}) => ({url:`api/scripts/question/`, method:'GET', params}),
            create: () => ({url:`api/scripts/question/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/question/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/question/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/question/${id}/`, method:'DELETE'})
        },
        script: {
            list: (params = {}) => ({url:`api/scripts/script/`, method:'GET', params}),
            create: () => ({url:`api/scripts/script/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/script/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/script/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/script/${id}/`, method:'DELETE'}),
            deep: {
                retrieve: (id) => ({url:`api/scripts/script/${id}/deep/`, method:'GET'})
            }
        },
        stage: {
            list: (params = {}) => ({url:`api/scripts/stage/`, method:'GET', params}),
            create: () => ({url:`api/scripts/stage/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/stage/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/stage/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/stage/${id}/`, method:'DELETE'})
        },
        variable: {
            list: (params = {}) => ({url:`api/scripts/variable/`, method:'GET', params}),
            create: () => ({url:`api/scripts/variable/`, method:'POST'}),
            retrieve: (id) => ({url:`api/scripts/variable/${id}/`, method:'GET'}),
            update: (id) => ({url:`api/scripts/variable/${id}/`, method:'PUT'}),
            delete: (id) => ({url:`api/scripts/variable/${id}/`, method:'DELETE'})
        }
    },
    telephony: {
        contact: {
            // Получение контакта по id
            retrieve: (id) => ({url:`api/telephony/contact/${id}/`, method:'GET'}),
            // Изменение контакта
            partial: (id) => ({url:`api/telephony/contact/${id}/`, method:'PATCH'}),
            delete: (id) => ({url:`api/telephony/contact/${id}/`, method:'DELETE'}),
            // Получение списка контактов для объекта
            list: (object_id, object_type, params = {}) => ({url:`api/telephony/contact/${object_type}/${object_id}/`, method:'GET', params}),
            // Создание контакта для объекта
            create: (object_id, object_type) => ({url:`api/telephony/contact/${object_type}/${object_id}/`, method:'POST'})
        },
        phone: {
            // Получение списка телефонов для объекта
            list: (data_type, object_id, params = {}) => ({url:`api/telephony/phone/${data_type}/${object_id}/`, method:'GET', params}),
            // Создание телефона
            create: (data_type, object_id) => ({url:`api/telephony/phone/${data_type}/${object_id}/`, method:'POST'}),
            // Получение телефона
            retrieve: (data_type, id, object_id) => ({url:`api/telephony/phone/${data_type}/${object_id}/${id}/`, method:'GET'}),
            // Обновление поля info у телефона
            partial: (data_type, id, object_id) => ({url:`api/telephony/phone/${data_type}/${object_id}/${id}/`, method:'PATCH'}),
            // Удаление телефона
            delete: (data_type, id, object_id) => ({url:`api/telephony/phone/${data_type}/${object_id}/${id}/`, method:'DELETE'})
        },
        telecall: {
            // Получение списка телефонных звонков
            list: (params = {}) => ({url:`api/telephony/telecall/`, method:'GET', params}),
            // Создание телефонного звонка
            create: () => ({url:`api/telephony/telecall/`, method:'POST'})
        }
    },
    version: {
        // Получение информации о версии
        retrieve: () => ({url:`api/version/`, method:'GET'})
    }
}
