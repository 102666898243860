// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PeriodApiMethodsCls {
    async periodRetrieve(wh, id) {
      // Получение периода отеля
      console.log('periodRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('hms__period', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'hms__period', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async periodUpdate(wh, id, data) {
      // data: PeriodUpdate
      // Обновление дат периода отеля
      console.log('periodUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async periodDelete(wh, id) {
      // Удаление периода отеля
      console.log('periodDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async periodActivatePartial(wh, id, data) {
      // data: PeriodActivate
      // Обновление поля is_active периода (Period)
      console.log('periodActivatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.period.activate.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.periodApi = new PeriodApiMethodsCls()
}

