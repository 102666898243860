// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class PersonApiMethodsCls {
    async personList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Person
      // filters: name, surname, patronymic, passport_number, fio
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('personList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async personCreate(wh, data) {
      // data: PersonPost
      // Создание Person
      // Если роль не CUSTOMER, то должен быть указан buyer
      console.log('personCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async personRetrieve(wh, id) {
      // Получение Person
      console.log('personRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('core__person', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'core__person', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async personUpdate(wh, id, data) {
      // data: PersonUpdate
      // Обновление Person
      console.log('personUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async personDelete(wh, id) {
      // Удаление Person
      console.log('personDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async personResponsiblePartial(wh, id, data) {
      // data: PersonChangeResponsible
      // Изменение responsible у Person
      console.log('personResponsiblePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.person.responsible.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.personApi = new PersonApiMethodsCls()
}

