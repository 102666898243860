// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BankAccountApiMethodsCls {
    async bankAccountList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка BankAccount
      // filters: id, bank_name, entity, ks, rs, bik
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('bankAccountList')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async bankAccountCreate(wh, data) {
      // data: BankAccount
      // Создание BankAccount
      console.log('bankAccountCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bankAccountRetrieve(wh, id) {
      // Получение модели BankAccount
      console.log('bankAccountRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('legal__bankaccount', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'legal__bankaccount', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bankAccountDelete(wh, id) {
      // Удаление BankAccount
      console.log('bankAccountDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bankAccountPartial(wh, id, data) {
      // data: BankAccount
      console.log('bankAccountPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bankAccountSetMainPartial(wh, id, data) {
      // data: BankAccount
      // Сделать счёт основным для юр. лица
      console.log('bankAccountSetMainPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.legal.bankAccount.setMain.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.bank_accountApi = new BankAccountApiMethodsCls()
}

