// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AuthApiMethodsCls {
    async acceptDataCreate(wh, data) {
      // data: AcceptInviteStageThree
      // Принятие инвайта, стадия 3
      // Создание User (или получение, в случае если есть Identity c ролью CUSTOMER) + создание Identity
      console.log('acceptDataCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.accept.data.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async acceptPincodeCreate(wh, data) {
      // data: AcceptInviteStageTwo
      // Принятие инвайта, стадия 2
      // Проверка пинкода, возвращение данных инвайта
      console.log('acceptPincodeCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.accept.pincode.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async acceptStartCreate(wh, data) {
      // data: AcceptInviteStageOne
      // Принятие инвайта, стадия 1
      // Получение телефона, инвайта
      console.log('acceptStartCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.accept.start.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async cloneCreate(wh, data) {
      // data: CloneTokenView
      // Клонирование токена
      // Клонирование токена выполняется для отвязки нового клиента от ранее полученного токена
      console.log('cloneCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.clone.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async customerCreate(wh, data) {
      // data: CustomerLogin
      // Аутентификация заказчика
      // выполняется при работе со сторонними сайтами
      console.log('customerCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customer.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async customerRegisterDataCreate(wh, data) {
      // data: StageThreeCustomerRegister
      // Регистрация заказчика, стадия 3
      console.log('customerRegisterDataCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customerRegister.data.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async customerRegisterPincodeCreate(wh, data) {
      // data: StageTwo
      // Регистрация заказчика, стадия 2
      console.log('customerRegisterPincodeCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customerRegister.pincode.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async customerRegisterStartCreate(wh, data) {
      // data: StageOneCustomerRegister
      // Регистрация заказчика, стадия 1
      console.log('customerRegisterStartCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.customerRegister.start.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async personalCreate(wh, data) {
      // data: PersonalLogin
      // Аутентификация пользователя
      // выполняется при работе из веб-морды
      console.log('personalCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.personal.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async pincodePincodeCreate(wh, data) {
      // data: StageTwo
      // Авторизация по пинкоду, стадия 2
      console.log('pincodePincodeCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.pincode.pincode.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async pincodeStartCreate(wh, data) {
      // data: StageOneAuthByPinCode
      // Авторизация по пинкоду, стадия 1
      // Получение телефона, генерация токена, пинкода
      console.log('pincodeStartCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.pincode.start.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async refreshCreate(wh, data) {
      // data: RefreshToken
      // Перегенерация токена
      // Перегенерация токена должна выполняться когда срок действия основного токена подходит концу или уже завершен
      console.log('refreshCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.refresh.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async registerDataCreate(wh, data) {
      // data: StageThree
      // Регистрация, стадия 3
      // Регистрация Company, Entity, User, Identity
      console.log('registerDataCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.register.data.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async registerPincodeCreate(wh, data) {
      // data: StageTwo
      // Регистрация, стадия 2
      // Проверка пинкода
      console.log('registerPincodeCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.register.pincode.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async registerStartCreate(wh, data) {
      // data: StageOne
      // Регистрация, стадия 1
      // Получение телефона, генерация токена, пинкода
      console.log('registerStartCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.register.start.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async restorePincodeCreate(wh, data) {
      // data: StageTwo
      // Запрос восстановления пароля, стадия 2
      // Проверка пинкода, авторизация
      console.log('restorePincodeCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.restore.pincode.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async restoreStartCreate(wh, data) {
      // data: StageOneAuthByPinCode
      // Запрос восстановления пароля, стадия 1
      // Получение телефона, генерация токена, пинкода
      console.log('restoreStartCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.auth.restore.start.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.authApi = new AuthApiMethodsCls()
}

