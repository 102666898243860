// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class MessageApiMethodsCls {
    async chatList(wh, {limit, offset, page} = {}) {
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('chatList')
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async chatCreate(wh, data) {
      // data: Chat
      console.log('chatCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async chatRetrieve(wh, id) {
      console.log('chatRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('messaging__message', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'messaging__message', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async chatUpdate(wh, id, data) {
      // data: Chat
      console.log('chatUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async chatDelete(wh, id) {
      console.log('chatDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async chatPartial(wh, id, data) {
      // data: Chat
      console.log('chatPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.message.chat.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async handleListList(wh) {
      // Получение списка транспортов
      console.log('handleListList')
      const response = await ctx.$services.fetch.request({config: endpoints.message.handleList.list()})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async messageList(wh, {limit, offset, page} = {}) {
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('messageList')
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async messageCreate(wh, data) {
      // data: Message
      console.log('messageCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async messageRetrieve(wh, id) {
      console.log('messageRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('messaging__message', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'messaging__message', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async messageUpdate(wh, id, data) {
      // data: Message
      console.log('messageUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async messageDelete(wh, id) {
      console.log('messageDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async messagePartial(wh, id, data) {
      // data: Message
      console.log('messagePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.message.message.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.messageApi = new MessageApiMethodsCls()
}

