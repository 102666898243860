// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class HotelApiMethodsCls {
    async hotelList(wh, {limit, offset, page, sort, filters} = {}) {
      // Список отелей
      // filters: title, description, is_active, title_with_city, company
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelList')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async hotelCreate(wh, data) {
      // data: Hotel
      // Создание отеля
      console.log('hotelCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelFindPut(wh, data) {
      // data: HotelFind
      // Поиск отеля по региону, заболеваниям и услугам
      console.log('hotelFindPut')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.find.put(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelRetrieve(wh, id) {
      // Получение отеля по id
      console.log('hotelRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('refbook__hotel', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'refbook__hotel', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelUpdate(wh, id, data) {
      // data: Hotel
      // Изменение отеля
      console.log('hotelUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelDelete(wh, id) {
      // Удаление отеля (не использовать, будет удалено)
      console.log('hotelDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelActivatePartial(wh, id, data) {
      // data: HotelActivate
      // Изменение поля is_active для отеля
      console.log('hotelActivatePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.activate.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelAttributeRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка атрибутов отеля
      // filters: created_at, updated_at, attribute, value
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelAttributeRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('refbook__hotel', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'refbook__hotel', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.attribute.retrieve(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async hotelAttributePost(wh, id, data) {
      // data: HotelAttributeValueCreate
      // Создание атрибута отеля
      console.log('hotelAttributePost')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.attribute.post(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelDeepRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получить модель отеля с вложенными сущностями
      // filters: title, description, is_active, title_with_city, company
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelDeepRetrieve')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.deep.retrieve(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
  }

  ctx.$services.hotelApi = new HotelApiMethodsCls()
}

