// Этот файл сгенерирован автоматически. Не надо его редактировать.
// Здесь описаны константы из бэкенда

export default {
  SYSTEM_COMPANY_ID:1,
  SYSTEM_USER_ID:1,
  FETCH_EMAIL_TEST_PERIOD:60,
  FETCH_EMAIL_TEST_FREQUENCY:1,
  DOCUMENT_TYPE_LENGTH:30,
  DOCUMENT_NUMBER_LENGTH:30,
  DOCUMENT_ISSUER_LENGTH:100,
  PERSON_NAME_LENGTH:30,
  PERSON_FIO_LENGTH:92,
  POSITION_LENGTH:250,
  COMPANY_NAME_LENGTH:250,
  INFO_LENGTH:250,
  LONG_TITLE_LENGTH:250,
  SHORT_TITLE_LENGTH:60,
  FILE_NAME_LENGTH:250,
  ADDRESS_LENGTH:250,
  FINANCE_INFO_LENGTH:500,
  ROOM_NUMBER_LENGTH:10,
  PHONE_MAX_LENGTH:20,
  EMAIL_MAX_LENGTH:100,
  URL_LENGTH:2048,
  TOKEN_MAX_LENGTH:1000,
  PIN_MAX_LENGTH:10,
  PASSWORD_LENGTH:100,
  HANDLE_LENGTH:20,
  EXTERNAL_HANDLE_LENGTH:20,
  CITY_NAME_LENGTH:30,
  ORGN_FIXED_LENGTH_MIN:13,
  ORGN_FIXED_LENGTH_MAX:15,
  INN_FIXED_LENGTH_MIN:10,
  INN_FIXED_LENGTH_MAX:12,
  KPP_LENGTH:9,
  BIK_LENGTH:9,
  OKPO_FIXED_LENGTH_MIN:8,
  OKPO_FIXED_LENGTH_MAX:10,
  BANK_ACCOUNT_LENGTH:20,
  FMS_PROFESSION_LENGTH:30,


// ========================================  -- ROLES --  ======================================== 

  ROLES_SUPERADMIN:"superadmin",
  ROLES_OWNER:"owner",
  ROLES_ACCOUNTANT:"accountant",
  ROLES_WEBMASTER:"webmaster",
  ROLES_JURIST:"jurist",
  ROLES_MANAGER:"manager",
  ROLES_AGENT:"agent",
  ROLES_TECH:"tech",
  ROLES_HOTELIER:"hotelier",
  ROLES_SALES:"sales",
  ROLES_TRANSFER:"transfer",
  ROLES_DRIVER:"driver",
  ROLES_CUSTOMER:"customer",
  ROLES_OFFLINE:"offline",
  ROLES_TELEPHONY:"telephony",
  ROLES_WEBFORM:"webform",
  ROLES_SYSTEM:"system",

  ROLES_TITLES:{
    superadmin:"Суперадмин",
    owner:"Руководитель",
    accountant:"Бухгалтер",
    webmaster:"Вебмастер",
    jurist:"Юрист",
    manager:"Менеджер системы",
    agent:"Агент",
    tech:"Техотдел",
    hotelier:"Менеджер отеля",
    sales:"Поставщик услуг",
    transfer:"Перевозчик",
    driver:"Водитель",
    customer:"Заказчик",
    offline:"Без доступа",
    telephony:"Телефония",
    webform:"Для форм",
    system:"Системный",
  },


// ========================================  -- BOOKING_STATUS --  ======================================== 

  BOOKING_STATUS_DRAFT:1,
  BOOKING_STATUS_REQUEST:2,
  BOOKING_STATUS_BOOKED:3,
  BOOKING_STATUS_PAYED:4,
  BOOKING_STATUS_CANCELLED:5,

  BOOKING_STATUS_TITLES:{
    1:"Черновик",
    2:"Запрос",
    3:"Забронировано",
    4:"Оплачено",
    5:"Отменено",
  },


// ========================================  -- COMMENT_TYPE --  ======================================== 

  COMMENT_TYPE_OWNER:1,
  COMMENT_TYPE_COMPANY:2,
  COMMENT_TYPE_PUBLIC:3,
  COMMENT_TYPE_CLIENT:4,
  COMMENT_TYPE_INTERNAL:5,

  COMMENT_TYPE_TITLES:{
    1:"Комментарий доступен только автору",
    2:"Комментарий доступен только всем сотрудникам компании",
    3:"Комментарий доступен только всем",
    4:"Комментарий доступен автору, системе и владельцу объекта",
    5:"Комментарий доступен всем пользователям кроме заказчиков и гостей",
  },


// ========================================  -- LEAD_SOURCE --  ======================================== 

  LEAD_SOURCE_MANUALLY:1,
  LEAD_SOURCE_SITE:2,
  LEAD_SOURCE_MESSENGER:3,
  LEAD_SOURCE_PHONE:4,
  LEAD_SOURCE_OTHER:5,

  LEAD_SOURCE_TITLES:{
    1:"Вручную",
    2:"Сайт",
    3:"Мессенджер",
    4:"Телефон",
    5:"Прочее",
  },


// ========================================  -- LEAD_STATUS --  ======================================== 

  LEAD_STATUS_NEW:1,
  LEAD_STATUS_TAKEN:2,
  LEAD_STATUS_POSTPONED:3,
  LEAD_STATUS_ARCHIVED:4,

  LEAD_STATUS_TITLES:{
    1:"Новая",
    2:"В обработке",
    3:"Отложена",
    4:"В архиве",
  },


// ========================================  -- LEAD_RESOLUTION --  ======================================== 

  LEAD_RESOLUTION_UNDEF:0,
  LEAD_RESOLUTION_VOUCHER:1,
  LEAD_RESOLUTION_DOUBLE:2,
  LEAD_RESOLUTION_REFUSED:3,
  LEAD_RESOLUTION_BAD_DATA:4,
  LEAD_RESOLUTION_NOP_LACES:5,
  LEAD_RESOLUTION_SPAM:6,
  LEAD_RESOLUTION_PRIVILEGES:7,
  LEAD_RESOLUTION_HAS_BOOK:8,
  LEAD_RESOLUTION_ILLNESS:9,
  LEAD_RESOLUTION_CHANGE:10,
  LEAD_RESOLUTION_BOOK_OTHER:11,
  LEAD_RESOLUTION_BOOK_DIRECT:12,

  LEAD_RESOLUTION_TITLES:{
    0:"Не определено",
    1:"Оформлена путевка",
    2:"Дубликат",
    3:"Отказ",
    4:"Некорректные данные",
    5:"Нет мест",
    6:"Спам",
    7:"Есть уже льготная путевка",
    8:"Есть бронь в объекте отдыха",
    9:"Отказ по болезни",
    10:"Отказ передумал ехать",
    11:"Отказ забронировал в другом агентстве",
    12:"Отказ забронировал напрямую в объекте",
  },


// ========================================  -- AGENCY_ENTRY_OPCODE --  ======================================== 

  AGENCY_ENTRY_OPCODE_NULL:0,
  AGENCY_ENTRY_OPCODE_CUSTOM:1,
  AGENCY_ENTRY_OPCODE_STORNO:2,
  AGENCY_ENTRY_OPCODE_INCOME:3,
  AGENCY_ENTRY_OPCODE_CREDIT:4,
  AGENCY_ENTRY_OPCODE_REPAYMENT:5,
  AGENCY_ENTRY_OPCODE_BLOCKING:6,
  AGENCY_ENTRY_OPCODE_REFUND:7,
  AGENCY_ENTRY_OPCODE_FAULTY_DEPOSIT:8,
  AGENCY_ENTRY_OPCODE_UNBLOCKING:9,
  AGENCY_ENTRY_OPCODE_BOOKING:10,
  AGENCY_ENTRY_OPCODE_CANCEL_BOOKING:11,
  AGENCY_ENTRY_OPCODE_REFUND_DECISION:12,
  AGENCY_ENTRY_OPCODE_REFUND_BLOCKED_DECISION:13,
  AGENCY_ENTRY_OPCODE_INCOME_FOR_REPAYMENT:14,
  AGENCY_ENTRY_OPCODE_SERVICE_PROVIDED:15,

  AGENCY_ENTRY_OPCODE_TITLES:{
    0:"Нулевая проводка",
    1:"Вручную указанные поля для бух. проводки",
    2:"Отмена любой предыдущей операции",
    3:"Поступления денежных средств от заказчика",
    4:"Предоставление кредита заказчику",
    5:"Возврат кредита заказчиком",
    6:"Блокировка денежных средств заказчика",
    7:"Возврат денежных средств заказчику",
    8:"Ошибочное зачисление",
    9:"Разблокировка денежных средств заказчика",
    10:"Бронирование",
    11:"Отмена бронирования",
    12:"Решение о возврате денежных средств заказчику",
    13:"Решение о возврате заблокированных денежных средств заказчику",
    14:"Зачисление в счет погашения кредита",
    15:"Оказаны услуги",
  },


// ========================================  -- AGENCY_PAYMENT_TYPE --  ======================================== 

  AGENCY_PAYMENT_TYPE_PAYMENT:0,
  AGENCY_PAYMENT_TYPE_REFUND:1,

  AGENCY_PAYMENT_TYPE_TITLES:{
    0:"оплата",
    1:"возврат",
  },


// ========================================  -- ENTRY_OPCODE --  ======================================== 

  ENTRY_OPCODE_NULL:0,
  ENTRY_OPCODE_CUSTOM:1,
  ENTRY_OPCODE_STORNO:2,
  ENTRY_OPCODE_INCOME:10,
  ENTRY_OPCODE_OUTCOME:11,
  ENTRY_OPCODE_OUTCOME_FEE:12,
  ENTRY_OPCODE_INCOME_PENALTY:13,
  ENTRY_OPCODE_S_OUTCOME:20,
  ENTRY_OPCODE_S_OUTCOME_MULCT:21,
  ENTRY_OPCODE_S_INCOME:22,
  ENTRY_OPCODE_S_INCOME_REWARD:23,
  ENTRY_OPCODE_BLOCK:30,
  ENTRY_OPCODE_UNBLOCK:31,
  ENTRY_OPCODE_CREDIT:40,
  ENTRY_OPCODE_CREDIT_FEE:41,
  ENTRY_OPCODE_RE_CREDIT:42,
  ENTRY_OPCODE_RE_CREDIT_FEE:43,
  ENTRY_OPCODE_BOOK:50,
  ENTRY_OPCODE_CANCEL_BOOK:51,
  ENTRY_OPCODE_FINISH_BOOK:52,
  ENTRY_OPCODE_FEE:53,
  ENTRY_OPCODE_PENALTY:54,
  ENTRY_OPCODE_REFUND:55,
  ENTRY_OPCODE_RESERVE:60,
  ENTRY_OPCODE_CANCEL_RESERVE:61,
  ENTRY_OPCODE_FINISH_RESERVE:62,
  ENTRY_OPCODE_REWARD:63,
  ENTRY_OPCODE_MULCT:64,
  ENTRY_OPCODE_REPAYMENT:65,
  ENTRY_OPCODE_DEBIT:70,
  ENTRY_OPCODE_DEBIT_REWARD:71,
  ENTRY_OPCODE_RE_DEBIT:72,
  ENTRY_OPCODE_RE_DEBIT_FEE:73,

  ENTRY_OPCODE_TITLES:{
    0:"Пустая операция",
    1:"Операция введённая вручную",
    2:"Операция сторно",
    10:"Поступления денежных средств от агентства",
    11:"Возврат агентству",
    12:"Оплата вознаграждения агентству",
    13:"Поступления в счёт оплаты штрафов от агентства",
    20:"Оплата поставщику",
    21:"Оплата штрафа поставщику",
    22:"Возврат от поставщика",
    23:"Оплата вознаграждения от поставщика",
    30:"Блокировка",
    31:"Отмена блокировки",
    40:"Предоставление кредита агентству",
    41:"Предоставление кредита агентству (в пределах агентского вознаграждения)",
    42:"Возврат кредита от агентства",
    43:"Возврат кредита от агентства (в пределах агентского вознаграждения)",
    50:"Бронирование агентством",
    51:"Отмена бронирования агентством",
    52:"Списание средств за оказание услуг",
    53:"Начисление вознаграждения агентству",
    54:"Выставление штрафа агентству",
    55:"Решение о возврате",
    60:"Бронирование у поставщика",
    61:"Отмена бронирования у поставщика",
    62:"Списание средств у поставщика за оказание услуг",
    63:"Начисление вознаграждения поставщиком",
    64:"Выставление штрафа поставщиком",
    65:"Решение о возврате поставщиком",
    70:"Предоставление кредита поставщиком",
    71:"Предоставление кредита поставщиком (в пределах агентского вознаграждения)",
    72:"Возврат кредита от поставщика",
    73:"Возврат кредита поставщику (в пределах агентского вознаграждения)",
  },


// ========================================  -- PAYMENT_TYPE --  ======================================== 

  PAYMENT_TYPE_AGENCY_PENALTY:1,
  PAYMENT_TYPE_HOTELIER_MULCT:2,
  PAYMENT_TYPE_AGENCY:3,
  PAYMENT_TYPE_AGENCY_REFUND:4,
  PAYMENT_TYPE_AGENCY_FEE:5,
  PAYMENT_TYPE_HOTELIER:6,
  PAYMENT_TYPE_HOTELIER_REFUND:7,
  PAYMENT_TYPE_HOTELIER_REWARD:8,

  PAYMENT_TYPE_TITLES:{
    1:"оплата штрафа агентством системе",
    2:"оплата штрафа системой отелю",
    3:"оплата агентством системе",
    4:"возврат системой агентству",
    5:"выплата вознаграждения системой агентству",
    6:"оплата системой отелю",
    7:"возврат отелем системе",
    8:"выплата вознаграждения отелем системе",
  },


// ========================================  -- OCCUPATION_STATUS --  ======================================== 

  OCCUPATION_STATUS_DRAFT:1,
  OCCUPATION_STATUS_CHECK_IN:2,
  OCCUPATION_STATUS_CHECK_OUT:3,
  OCCUPATION_STATUS_CANCELLED:4,

  OCCUPATION_STATUS_TITLES:{
    1:"Черновик",
    2:"Заехал",
    3:"Выехал",
    4:"отменено",
  },


// ========================================  -- RESERVATION_TYPE --  ======================================== 

  RESERVATION_TYPE_BOOKING:1,
  RESERVATION_TYPE_QUOTA:2,
  RESERVATION_TYPE_RESERVATION:3,

  RESERVATION_TYPE_TITLES:{
    1:"Бронь",
    2:"Квота",
    3:"Резерв",
  },


// ========================================  -- RESERVATION_STATUS --  ======================================== 

  RESERVATION_STATUS_DRAFT:1,
  RESERVATION_STATUS_ON_REQUEST:2,
  RESERVATION_STATUS_BOOKED:3,
  RESERVATION_STATUS_PAYED:4,
  RESERVATION_STATUS_CANCELLED:5,
  RESERVATION_STATUS_CHECKIN:6,

  RESERVATION_STATUS_TITLES:{
    1:"Черновик",
    2:"Под запрос",
    3:"Забронировано",
    4:"Оплачено",
    5:"Отменено",
    6:"Заезд",
  },


// ========================================  -- ROOMFLAG_TYPE --  ======================================== 

  ROOMFLAG_TYPE_NEED_CLEAN:1,
  ROOMFLAG_TYPE_CLEAN:2,
  ROOMFLAG_TYPE_RED:3,
  ROOMFLAG_TYPE_GREEN:4,
  ROOMFLAG_TYPE_BLUE:5,
  ROOMFLAG_TYPE_YELLOW:6,
  ROOMFLAG_TYPE_CYAN:7,
  ROOMFLAG_TYPE_MAGENTA:8,

  ROOMFLAG_TYPE_TITLES:{
    1:"требует уборки",
    2:"убран",
    3:"красный",
    4:"зеленый",
    5:"синий",
    6:"желтый",
    7:"циан",
    8:"маджента",
  },


// ========================================  -- SPACE_TYPE --  ======================================== 

  SPACE_TYPE_BEDROOM:1,
  SPACE_TYPE_LIVING:2,
  SPACE_TYPE_HALL:3,
  SPACE_TYPE_KITCHEN:4,
  SPACE_TYPE_BATHROOM:5,
  SPACE_TYPE_DINING:6,

  SPACE_TYPE_TITLES:{
    1:"Спальня",
    2:"Гостиная",
    3:"Холл",
    4:"Кухня",
    5:"Ванная",
    6:"Столовая",
  },


// ========================================  -- AGENCY_CONTRACT_SETTLEMENT_METHOD --  ======================================== 

  AGENCY_CONTRACT_SETTLEMENT_METHOD_FULL_SUM:0,
  AGENCY_CONTRACT_SETTLEMENT_METHOD_SUB_COMMISSION:1,

  AGENCY_CONTRACT_SETTLEMENT_METHOD_TITLES:{
    0:"Полная сумма",
    1:"За вычетом агентского вознаграждения",
  },


// ========================================  -- DOCUMENT_STATE --  ======================================== 

  DOCUMENT_STATE_HELD:0,
  DOCUMENT_STATE_DRAFT:1,
  DOCUMENT_STATE_ANNULLED:2,
  DOCUMENT_STATE_PREDRAFT:3,

  DOCUMENT_STATE_TITLES:{
    0:"проведен",
    1:"черновик",
    2:"удален",
    3:"загружен",
  },


// ========================================  -- DOCUMENT_TEMPLATE_TYPE --  ======================================== 

  DOCUMENT_TEMPLATE_TYPE_SYSTEM_CONTRACT_AGENCY:1,
  DOCUMENT_TEMPLATE_TYPE_SYSTEM_CONTRACT_HOTELIER:2,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_CONTRACT_PERSON:3,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_CONTRACT_ORG:4,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_RECEIPT_PERSON:5,
  DOCUMENT_TEMPLATE_TYPE_AGENCY_RECEIPT_ORG:6,

  DOCUMENT_TEMPLATE_TYPE_TITLES:{
    1:"Договор системы с агентством",
    2:"Договор системы с отелем",
    3:"Договор агентства с заказчиком",
    4:"Договор агентства с заказчиком - юр. лицом",
    5:"Счет агентства заказчику физлицу",
    6:"Счет агентства заказчику юр. лицу",
  },


// ========================================  -- DOCUMENT_TEMPLATE_FORMAT --  ======================================== 

  DOCUMENT_TEMPLATE_FORMAT_MARKDOWN:1,
  DOCUMENT_TEMPLATE_FORMAT_HTML:2,
  DOCUMENT_TEMPLATE_FORMAT_WWW:3,

  DOCUMENT_TEMPLATE_FORMAT_TITLES:{
    1:"Шаблон в формате «MarkDown»",
    2:"Шаблон в формате HTML",
    3:"Шаблон в формате HTML для WWW (веб-страница целиком)",
  },


// ========================================  -- HOTELIER_CONTRACT_CALCULATION_TYPE --  ======================================== 

  HOTELIER_CONTRACT_CALCULATION_TYPE_CHECK_IN:0,
  HOTELIER_CONTRACT_CALCULATION_TYPE_DAILY:1,

  HOTELIER_CONTRACT_CALCULATION_TYPE_TITLES:{
    0:"расчет стоимости по цене на дату заезда",
    1:"расчет стоимости по цене кадого дня",
  },


// ========================================  -- HOTELIER_CONTRACT_INVOICE_TYPE --  ======================================== 

  HOTELIER_CONTRACT_INVOICE_TYPE_INCLUDE_REWARD:1,
  HOTELIER_CONTRACT_INVOICE_TYPE_DOESNT_INCLUDE_REWARD:2,

  HOTELIER_CONTRACT_INVOICE_TYPE_TITLES:{
    1:"включает вознаграждение",
    2:"не включает вознаграждение",
  },


// ========================================  -- HOTELIER_CONTRACT_PAYMENT_TYPE --  ======================================== 

  HOTELIER_CONTRACT_PAYMENT_TYPE_WITH_REWARD:1,
  HOTELIER_CONTRACT_PAYMENT_TYPE_WITHOUT_REWARD:2,

  HOTELIER_CONTRACT_PAYMENT_TYPE_TITLES:{
    1:"полной суммы",
    2:"за вычетом вознаграждения",
  },


// ========================================  -- HOTELIER_CONTRACT_REWARD_TYPE --  ======================================== 

  HOTELIER_CONTRACT_REWARD_TYPE_CHECKIN:1,
  HOTELIER_CONTRACT_REWARD_TYPE_CHECKOUT:2,
  HOTELIER_CONTRACT_REWARD_TYPE_DAILY:3,
  HOTELIER_CONTRACT_REWARD_TYPE_BUY:4,

  HOTELIER_CONTRACT_REWARD_TYPE_TITLES:{
    1:"по дате заезда",
    2:"по дате выезда",
    3:"с учетом переходного периода",
    4:"по дате покупки",
  },


// ========================================  -- IMPORT_FILE_STATE --  ======================================== 

  IMPORT_FILE_STATE_LOADED:0,
  IMPORT_FILE_STATE_PROCESSED:1,
  IMPORT_FILE_STATE_FAILED:2,
  IMPORT_FILE_STATE_ACCEPTED:3,
  IMPORT_FILE_STATE_CANCELLED:4,

  IMPORT_FILE_STATE_TITLES:{
    0:"загружен",
    1:"обработан",
    2:"не обработан",
    3:"принят",
    4:"отменено",
  },


// ========================================  -- CHAT_TYPE --  ======================================== 

  CHAT_TYPE_LEAD:1,
  CHAT_TYPE_TOUR:2,
  CHAT_TYPE_PUBLIC:3,
  CHAT_TYPE_SYSTEM:4,
  CHAT_TYPE_PRIVATE:5,
  CHAT_TYPE_TOUR_SYS:6,

  CHAT_TYPE_TITLES:{
    1:"чат с лидом",
    2:"чат с заказчиком по туру",
    3:"групповой чат",
    4:"чат с системой",
    5:"приватный чат",
    6:"чат с системой по туру",
  },


// ========================================  -- HOTEL_MODE --  ======================================== 

  HOTEL_MODE_MANAGED:1,
  HOTEL_MODE_EXTERN_HMS:2,

  HOTEL_MODE_TITLES:{
    1:"полный контроль",
    2:"внешняя САУ",
  },


// ========================================  -- HOTEL_DISPLAY --  ======================================== 

  HOTEL_DISPLAY_HOTEL:1,
  HOTEL_DISPLAY_CAMP:2,
  HOTEL_DISPLAY_CRUISE:3,
  HOTEL_DISPLAY_SANATORIUM:4,

  HOTEL_DISPLAY_TITLES:{
    1:"Отель",
    2:"Лагерь со сменами",
    3:"Круиз",
    4:"Санаторий",
  },


// ========================================  -- LOCATION_ADDR_TYPE --  ======================================== 

  LOCATION_ADDR_TYPE_AREA:0,
  LOCATION_ADDR_TYPE_REGION:1,
  LOCATION_ADDR_TYPE_COUNTRY:2,
  LOCATION_ADDR_TYPE_STATE:3,
  LOCATION_ADDR_TYPE_CITY:4,
  LOCATION_ADDR_TYPE_TERRITORY:5,
  LOCATION_ADDR_TYPE_STREET:6,
  LOCATION_ADDR_TYPE_ADDRESS:7,

  LOCATION_ADDR_TYPE_TITLES:{
    0:"Часть света",
    1:"Регион",
    2:"Страна",
    3:"Область, штат",
    4:"Населенный пункт",
    5:"Территория",
    6:"Улица",
    7:"Адрес",
  },


// ========================================  -- QUESTION_TYPE --  ======================================== 

  QUESTION_TYPE_INPUT:0,
  QUESTION_TYPE_BOOL:1,
  QUESTION_TYPE_BUTTONS:2,
  QUESTION_TYPE_DB_CHOICE:3,
  QUESTION_TYPE_MULTIPLE_CHOICE:4,
  QUESTION_TYPE_MULTIPLE_DB_CHOICE:5,
  QUESTION_TYPE_INFO_BLOCK:6,
  QUESTION_TYPE_COMPOUND_QUESTION:7,

  QUESTION_TYPE_TITLES:{
    0:"Вводятся какие то данные в инпут",
    1:"Да/Нет",
    2:"Выбор из списка ответов (Отображается как список кнопок)",
    3:"Выбор из бд (Отображается как select)",
    4:"Множественный выбор (список) (Select с multi выбором)",
    5:"Множественный выбор (бд) (Select с multi выбором)",
    6:"Вопрос не выявлет какое либо знание",
    7:"Составной вопрос",
  },


// ========================================  -- TELECALL_EVENT --  ======================================== 

  TELECALL_EVENT_START:1,
  TELECALL_EVENT_TAKEN:2,
  TELECALL_EVENT_END:3,
  TELECALL_EVENT_MISSED:4,
  TELECALL_EVENT_MANAGER:5,

  TELECALL_EVENT_TITLES:{
    1:"Начало",
    2:"Принят",
    3:"Завершение",
    4:"Пропущен",
    5:"Запрос оператора",
  },


// ========================================  -- TELECALL_TYPE --  ======================================== 

  TELECALL_TYPE_IN:1,
  TELECALL_TYPE_OUT:2,
  TELECALL_TYPE_BACK:3,

  TELECALL_TYPE_TITLES:{
    1:"Входящий",
    2:"Исходящий",
    3:"Обратный",
  },


// ========================================  -- FMS_DOCUMENT_TYPE --  ======================================== 

  FMS_DOCUMENT_TYPE_A:"visa",
  FMS_DOCUMENT_TYPE_B:"permit",
  FMS_DOCUMENT_TYPE_C:"temp_permit",

  FMS_DOCUMENT_TYPE_TITLES:{
    visa:"Виза",
    permit:"Вид на жительство",
    temp_permit:"Разрешение на временное проживание",
  },


// ========================================  -- FMS_DOCUMENT_ID_TYPE --  ======================================== 

  FMS_DOCUMENT_ID_TYPE_A:"foreign",
  FMS_DOCUMENT_ID_TYPE_B:"passport",
  FMS_DOCUMENT_ID_TYPE_C:"birth",
  FMS_DOCUMENT_ID_TYPE_D:"other",
  FMS_DOCUMENT_ID_TYPE_F:"kazakh",
  FMS_DOCUMENT_ID_TYPE_G:"service",
  FMS_DOCUMENT_ID_TYPE_H:"diplomat",
  FMS_DOCUMENT_ID_TYPE_J:"shelters",
  FMS_DOCUMENT_ID_TYPE_K:"sng",

  FMS_DOCUMENT_ID_TYPE_TITLES:{
    foreign:"Национальный заграничный паспорт",
    passport:"Иностранный паспорт",
    birth:"Иностранное свидетельство о рождении",
    other:"Иной документ, удостоверяющий личность",
    kazakh:"Удостоверение личности гражданина республики Казахстан",
    service:"Служебный паспорт",
    diplomat:"Дипломатический паспорт иностранного гражданина",
    shelters:"Свидетельство о предоставлении временного убежища на территории Российской Федерации",
    sng:"Удостоверение личности гражданина государств-участников СНГ",
  },


// ========================================  -- FMS_PURPOSE_OF_ENTRY --  ======================================== 

  FMS_PURPOSE_OF_ENTRY_A:"service",
  FMS_PURPOSE_OF_ENTRY_B:"tourism",
  FMS_PURPOSE_OF_ENTRY_C:"business",
  FMS_PURPOSE_OF_ENTRY_D:"education",
  FMS_PURPOSE_OF_ENTRY_E:"work",
  FMS_PURPOSE_OF_ENTRY_F:"private",
  FMS_PURPOSE_OF_ENTRY_G:"transit",
  FMS_PURPOSE_OF_ENTRY_H:"human",
  FMS_PURPOSE_OF_ENTRY_J:"other",

  FMS_PURPOSE_OF_ENTRY_TITLES:{
    service:"Служебная",
    tourism:"Туризм",
    business:"Деловая",
    education:"Учеба",
    work:"Работа",
    private:"Частная",
    transit:"Транзит",
    human:"Гуманитарная",
    other:"Другая",
  },


// ========================================  -- PERSON_PASSPORT_TYPE --  ======================================== 

  PERSON_PASSPORT_TYPE_PASSPORT:"passport",
  PERSON_PASSPORT_TYPE_BIRTH:"birth",
  PERSON_PASSPORT_TYPE_OTHER:"other",

  PERSON_PASSPORT_TYPE_TITLES:{
    passport:"паспорт РФ",
    birth:"свидетельство о рождении РФ",
    other:"Иной документ, удостоверяющий личность",
  },


// ========================================  -- PLACE_TYPE --  ======================================== 

  PLACE_TYPE_AUTO:"_auto",
  PLACE_TYPE_MAIN:"main",
  PLACE_TYPE_EXTRA:"ext",
  PLACE_TYPE_CHILD:"child",

  PLACE_TYPE_TITLES:{
    _auto:"назначается автоматически",
    main:"основное",
    ext:"дополнительное",
    child:"дополнительное детское",
  },


// ========================================  -- PLACE_TYPE_IN_CATEGORY_CHOICES --  ======================================== 

  PLACE_TYPE_IN_CATEGORY_CHOICES_GENERAL:"general",
  PLACE_TYPE_IN_CATEGORY_CHOICES_ADDITIONAL:"additional",

  PLACE_TYPE_IN_CATEGORY_CHOICES_TITLES:{
    general:"Основное",
    additional:"Дополнительное",
  },


// ========================================  -- MEDIA_FIXED_TITLES --  ======================================== 

  MEDIA_FIXED_TITLES_USER_PHOTO:"user_photo",
  MEDIA_FIXED_TITLES_USER_SIGNATURE:"user_signature",
  MEDIA_FIXED_TITLES_STAMP_IMAGE:"stamp_image",

  MEDIA_FIXED_TITLES_TITLES:{
    user_photo:"Фото пользователя",
    user_signature:"Подпись пользователя",
    stamp_image:"Фото печати",
  },


// ========================================  -- TRANSPORT_TYPE --  ======================================== 

  TRANSPORT_TYPE_EMAIL:"email",
  TRANSPORT_TYPE_SMS:"sms",
  TRANSPORT_TYPE_WEBCHAT:"webchat",
  TRANSPORT_TYPE_WHATSAPP:"whatsapp",
  TRANSPORT_TYPE_TELEGRAM:"telegram",

  TRANSPORT_TYPE_TITLES:{
    email:"Email",
    sms:"SMS",
    webchat:"Веб-чат",
    whatsapp:"Whatsapp",
    telegram:"Telegram",
  },


// ========================================  -- GENDER --  ======================================== 

  GENDER_MALE:"M",
  GENDER_FEMALE:"F",
  GENDER_TRANS:"T",
  GENDER_ELV:"E",
  GENDER_NH:"N",

  GENDER_TITLES:{
    M:"Мужчина",
    F:"Женщина",
    T:"Транссексуал",
    E:"Эльф",
    N:"Негуманод",
  },


// ========================================  -- VARIABLE_TYPE --  ======================================== 

  VARIABLE_TYPE_STR:"str",
  VARIABLE_TYPE_INT:"int",
  VARIABLE_TYPE_BOOL:"bool",
  VARIABLE_TYPE_INT_LIST:"int_list",
  VARIABLE_TYPE_STR_LIST:"str_list",

  VARIABLE_TYPE_TITLES:{
    str:"строка",
    int:"число",
    bool:"булевское значение",
    int_list:"список из чисел",
    str_list:"список из строк",
  },


// ========================================  -- AGENT_SALARY_ACCRUAL_STATUS --  ======================================== 

  AGENT_SALARY_ACCRUAL_STATUS_DRAFT:1,
  AGENT_SALARY_ACCRUAL_STATUS_APPROVED:2,

  AGENT_SALARY_ACCRUAL_STATUS_TITLES:{
    1:"Черновик",
    2:"Утверждено",
  },


// ========================================  -- OPERATION_STATUS --  ======================================== 

  OPERATION_STATUS_NEW:"new",
  OPERATION_STATUS_DONE:"done",
  OPERATION_STATUS_DENIED:"denied",
  OPERATION_STATUS_FAILED:"failed",

  OPERATION_STATUS_TITLES:{
    new:"Новая",
    done:"Завершена",
    denied:"Отклонена",
    failed:"Ошибка",
  },


// ========================================  -- OPERATION_TYPE --  ======================================== 

  OPERATION_TYPE_CANCELLED:0,
  OPERATION_TYPE_DRAFT:1,
  OPERATION_TYPE_REQUESTED:2,
  OPERATION_TYPE_PRESENCE:3,
  OPERATION_TYPE_AGREED:4,
  OPERATION_TYPE_ACCEPTED:5,
  OPERATION_TYPE_RESERVED:6,
  OPERATION_TYPE_BOOKED:7,

  OPERATION_TYPE_TITLES:{
    0:"Отменено",
    1:"Черновик",
    2:"Запрошено наличие мест",
    3:"Наличие мест",
    4:"Согласовано",
    5:"Принято",
    6:"Зарезервировано",
    7:"Забронировано",
  },


// ========================================  -- CONTACT_TYPE --  ======================================== 

  CONTACT_TYPE_TELEGRAM:"telegram",
  CONTACT_TYPE_WHATSAPP:"whatsapp",
  CONTACT_TYPE_VK:"vk",
  CONTACT_TYPE_OK:"ok",
  CONTACT_TYPE_SKYPE:"skype",
  CONTACT_TYPE_VIBER:"viber",
  CONTACT_TYPE_EMAIL:"email",
  CONTACT_TYPE_PHONE:"phone",
  CONTACT_TYPE_ICQ:"icq",

  CONTACT_TYPE_TITLES:{
    telegram:"Telegram",
    whatsapp:"Whatsapp",
    vk:"вКонтакте",
    ok:"Одноклассники",
    skype:"Skype",
    viber:"Viber",
    email:"E-mail",
    phone:"Телефон",
    icq:"ICQ",
  },
}
