import { hotelMethods } from '@/api'
import { hotelsAdapter, hotelAdapter } from '@/adaptors'

export default ctx => {
  class HotelService  {
    constructor() {
      this.hotelMethods = hotelMethods(ctx)
    }

    async getHotels() {
      const result = await this.hotelMethods.getHotels()
      ctx.$services.storage.setItem(result, 'hotels')
      const hotels = result?.data || []
      return hotelsAdapter(hotels)
    }

    async setActivityHotel(hotel, value) {
      const id = hotel?.id

      if (!id) {
        return false
      }

      return await this.hotelMethods.setActivityHotel(id, !value)
    }

    async setHotelAsWorker(hotel) {
      const id = hotel?.id

      if (!id) {
        return false
      }

      return  await this.hotelMethods.setHotelAsWorker(id)
    }

    async getWorkingHotel() {
      return await this.hotelMethods.getWorkingHotel()
    }

    async getHotelById(id) {
      if (!id) {
        return false
      }

      const result = ctx.$services.storage.getItemById('hotels', id) || hotelAdapter(await this.hotelMethods.getHotelById(id))
      return result
    }

  }
  ctx.$services.hotel = new HotelService()
}