// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class RoomApiMethodsCls {
    async roomList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Room
      // filters: sort_order, created_at, updated_at, category, number, name,
      //          prot_allow_external_booking, is_active
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('roomList')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async roomCreate(wh, data) {
      // data: RoomPost
      // Создание Room
      console.log('roomCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async roomRetrieve(wh, id) {
      // Получение Room
      console.log('roomRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('hms__room', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'hms__room', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async roomUpdate(wh, id, data) {
      // data: RoomPost
      // Обновление модели Room
      console.log('roomUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async roomFlagPost(wh, id, data) {
      // data: RoomFlag
      // Создание RoomFlag
      console.log('roomFlagPost')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.room.flag.post(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.roomApi = new RoomApiMethodsCls()
}

