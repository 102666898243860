import { userMethods } from '@/api'

export default ctx => {
  class UserService  {
    constructor() {
      this.userMethods = userMethods(ctx)
    }

    async getUsers(sortTable) {
      const result = await this.userMethods.getUsers(sortTable)
      ctx.$services.storage.setItem(result, 'users')
      const users = result?.data || [] // if array with data not defined return empty
      return {
        data: users.results, 
        count: result.data.count
      }
    }

    test() {
      this.userMethods.test()
    }

    async getAllTemplateSettings() {
      return await this.userMethods.test()
    }
  }
  ctx.$services.user = new UserService()
}