// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BalanceApiMethodsCls {
    async financeBalanceList(wh, {limit, offset, page, sort, filters} = {}) {
      // Получение текущих балансов всех контрагентов системы
      // filters: owner_entity, contractor_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('financeBalanceList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.finance.balance.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async financeBalanceForDateList(wh, date, {limit, offset, page, filters} = {}) {
      // Получение балансов всех контрагентов системы на дату
      // filters: owner_entity, contractor_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('financeBalanceForDateList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.finance.balanceForDate.list(date, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async financeContractorBalanceList(wh, contractor_entity_id, {limit, offset, page, sort, filters} = {}) {
      // Получение текущего баланса контрагента системы
      // filters: owner_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('financeContractorBalanceList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.finance.contractorBalance.list(contractor_entity_id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async financeContractorBalanceForDateList(wh, contractor_entity_id, date, {limit, offset, page, filters} = {}) {
      // Получение баланса контрагента системы на дату
      // filters: owner_entity
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('financeContractorBalanceForDateList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.finance.contractorBalanceForDate.list(contractor_entity_id, date, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
  }

  ctx.$services.balanceApi = new BalanceApiMethodsCls()
}

