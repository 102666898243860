// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ContactApiMethodsCls {
    async contactRetrieve(wh, id) {
      // Получение контакта по id
      console.log('contactRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('telephony__contact', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'telephony__contact', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async contactDelete(wh, id) {
      console.log('contactDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async contactPartial(wh, id, data) {
      // data: Contact
      // Изменение контакта
      console.log('contactPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async contactList(wh, object_id, object_type, {limit, offset, page, sort, filters} = {}) {
      // Получение списка контактов для объекта
      // filters: type, number, info, pk
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('contactList')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.list(object_id, object_type, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async contactCreate(wh, object_id, object_type, data) {
      // data: Contact
      // Создание контакта для объекта
      console.log('contactCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.telephony.contact.create(object_id, object_type), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.contactApi = new ContactApiMethodsCls()
}

