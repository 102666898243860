// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgeApiMethodsCls {
    async ageCreate(wh, data) {
      // data: Age
      // Создание Age
      console.log('ageCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.age.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async ageRetrieve(wh, id) {
      // Обновление Age
      console.log('ageRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('refbook__age', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'refbook__age', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.age.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async ageUpdate(wh, id, data) {
      // data: Age
      // Обновление Age
      console.log('ageUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.age.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async ageDelete(wh, id) {
      // Удаление Age
      console.log('ageDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.age.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async hotelAgesRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Age для отеля
      // filters: created_at, updated_at, fr, to
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelAgesRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('refbook__age', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'refbook__age', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.refbook.hotel.ages.retrieve(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
  }

  ctx.$services.ageApi = new AgeApiMethodsCls()
}

