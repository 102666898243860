import C from '@/config/back_const'

export default ctx => {
  class LeadService  {

    canChange(lead) {
      // TODO дописать права доступа

      const identity = ctx.$services.auth.getIdentity()

      if (lead.owner !== identity.company) {
        return false
      }

      if (lead.initiator === identity.id) {
        return lead.status === С.LEAD_STATUS_NEW
      }

      if (lead.agent === identity.id) {
        return lead.status === С.LEAD_STATUS_POSTPONED || lead.status === C.LEAD_STATUS_TAKEN
      }

      if (identity.role === C.ROLES_OWNER) {
        return lead.status === С.LEAD_STATUS_POSTPONED || lead.status === C.LEAD_STATUS_TAKEN ||  lead.status === С.LEAD_STATUS_NEW
      }

    }

  }
  ctx.$services.leadService = new LeadService()
}
