// import { useStore } from 'vuex'
// const vuexStore = useStore()

const WH = 'AUTH'
const REFRESH_THRESHOLD = 120 // 35980
let flag = false

function isDev() {
  return process.env.NODE_ENV === 'development'
}
// function isProd() {
//   return process.env.NODE_ENV === 'production'
// }

const sleep = ms => new Promise(r => setTimeout(r, ms));

export default ctx => {
  class AuthService  {

    constructor() {
      this.isRefreshing = false
      this._token = null
      this._temp_token_key = null
    }

    async getTokenKey() {
      while (this.isRefreshing) {
        await sleep(10)
      }
      return this._temp_token_key || this._token?.key
    }

    isAuthenticated() {
      return !!this._token
    }

    async goAuthenticated(parentLogin=false, retry_if_none=false, point) {
      // console.log(`----------------------------- goAuthenticated(${point})`)
      if (this._token) {
        // console.log(`goAuthenticated(${point}) - token exists`)
        return true
      }

      if (isDev()) {
        // console.log(`goAuthenticated(${point}) - isDev`)
        this._temp_token_key = localStorage.getItem('latestTokenKey')
        if (this._temp_token_key) {
          // console.log(`goAuthenticated(${point}) - this._temp_token_key`, this._temp_token_key)
          try {
            // console.log(`goAuthenticated(${point}) feth`)
            this._token = await ctx.$services.accountApi.authinfoRetrieve(WH)
            this._identity = await ctx.$services.identityApi.identityRetrieve(WH, this._token.identity)
          }
          catch (e) {
            // тихо умираем
          }
          delete this._temp_token_key
        }
      }

      if (this._token) {
        // console.log(`goAuthenticated(${point}) - token taken from localStorage`)
        return true
      }

      if (!retry_if_none) {
        return false
      }

      // console.log(`goAuthenticated(${point}) - token not found`)
      try {
        this._token = await ctx.$services.accountApi.authinfoRetrieve(WH)
      }
      catch (e) {
        if (e.response) {
          // console.log(`goAuthenticated(${point}) authinfoRetrieve failed with code `, e.response.status)
          if (e.response.status === 401) {
            if (!parentLogin) {
              return false
            }
            // console.log(`goAuthenticated(${point}) - return false`)
            return false
          }
        }
        console.error('goAuthenticated failed at', point)
        throw (e)
      }
      // console.log(`goAuthenticated(${point}) loaded token:`, this._token)
      this._identity = await ctx.$services.identityApi.identityRetrieve(WH, this._token.identity)
      // console.log(`goAuthenticated(${point}) authenticate identity:`, this._identity)
      // console.log(`goAuthenticated(${point}) succeed true`)
      return true
    }

    resetAuthentication() {
      this._token = null
      this._identity = null
      ctx.$services.storage.clr('AUTH')
      if (isDev()) {
        localStorage.removeItem('latestTokenKey')
      }
    }

    async authenticate(login, password) {
      ctx.$services.storage.clr(WH)
      this._token = await ctx.$services.authApi.personalCreate(WH, {login, password})
      let latestIdentity;
      try {
        latestIdentity = parseInt(localStorage.getItem('latestIdentity'))
      }
      catch {
        latestIdentity = null
      }
      if (latestIdentity && latestIdentity !== this._token.identity) {
        try {
          this._token = await ctx.$services.accountApi.changeCreate(WH, {identity: latestIdentity})
        }
        catch {
          console.log('Не удалось сменить роль')
        }
      }

      this._identity = await ctx.$services.identityApi.identityRetrieve(WH, this._token.identity)
      localStorage.setItem('latestIdentity', this._identity.id)
      if (isDev()) {
        localStorage.setItem('latestTokenKey', this._token.key)
        localStorage.setItem('latestRefreshKey', this._token.refresh_key)
      }
    }

    async getIdentity() {
      if (!this._identity) {
        this.resetAuthentication()
      }
      return this._identity
    }

    async changeIdentity (identity) {
      ctx.$services.storage.clr(WH)
      this._token = await ctx.$services.accountApi.changeCreate(WH, {identity})
      // console.log('changeIdentity token:', this._token)
      this._identity = await ctx.$services.identityApi.identityRetrieve(WH, this._token.identity)
      // console.log('authenticate identity:', this._identity)
      localStorage.setItem('latestIdentity', this._identity.id)
      if (isDev()) {
        localStorage.setItem('latestTokenKey', this._token.key)
        localStorage.setItem('latestRefreshKey', this._token.refresh_key)
      }
      return this._identity
    }

    async onRefreshTimer(self) {
      if (!self._token) {
        // console.log('onRefreshTimer - no auth', Date())
        return
      }
      const valid_till = new Date(self._token.valid_till)
      const now = new Date()
      const time_left = Math.round((valid_till - now)/1000)
      // console.log('onRefreshTimer', time_left,
      //   self._token.key.substring(self._token.key.length-20, self._token.key.length-1),
      //   self._token.key.substring(self._token.refresh_key.length-20, self._token.refresh_key.length-1)
      // )
      if (time_left < REFRESH_THRESHOLD) {
        // console.log("doRefresh")
        // self.isRefreshing = true
        try {
          self._token = await ctx.$services.authApi.refreshCreate(WH, {refresh_token:self._token.refresh_key})
          if (isDev()) {
            localStorage.setItem('latestTokenKey', self._token.key)
            localStorage.setItem('latestRefreshKey', self._token.refresh_key)
          }
          // console.log("doRefresh ok")
        }
        catch (e) {
          if (e.response) {
            if (e.response.status === 401) {
              self._token = null
              return false
            }
          }
          console.error('goAuthenticated failed at refresh token')
          throw (e)
        }
        //finally {
          // self.isRefreshing = false
        //}
      }
    }

    initRefresh() {
      setInterval(this.onRefreshTimer, 5000, this)
    }

  }
  if (flag) {
    assert(False, "Повторная инициализация AuthService")
  }
  flag = true
  ctx.$services.auth = new AuthService()
  ctx.$services.auth.initRefresh()
}
