// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class ReservationApiMethodsCls {
    async hotelReservationRetrieve(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка Reservation для отеля
      // filters: created_at, updated_at, status, company, entity, buyer, category, room,
      //          partial_places, group, type, date_in, time_in, date_out, time_out
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('hotelReservationRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('hms__reservation', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'hms__reservation', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.hms.hotel.reservation.retrieve(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async reservationCreate(wh, data) {
      // data: ReservationCreate
      // Создание Reservation
      console.log('reservationCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async reservationRetrieve(wh, id) {
      // Получение Reservation
      console.log('reservationRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('hms__reservation', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'hms__reservation', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async reservationUpdate(wh, id, data) {
      // data: ReservationUpdate
      // Обновление модели Reservation
      console.log('reservationUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async reservationDelete(wh, id) {
      // Удаление Reservation, только для статуса Draft
      console.log('reservationDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async reservationPartial(wh, id, data) {
      // data: ReservationUpdate
      // Обновление полей Reservation
      console.log('reservationPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async reservationBookPartial(wh, id, data) {
      // data: ReservationBook
      // Запрос на бронирование
      console.log('reservationBookPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.book.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async reservationCancelPartial(wh, id) {
      // Переводит в статус CANCELLED. Для любого статуса, кроме DRAFT
      console.log('reservationCancelPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.cancel.partial(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async reservationConfirmPartial(wh, id) {
      // Переводит статус ON_REQUEST в BOOKED
      console.log('reservationConfirmPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.hms.reservation.confirm.partial(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.reservationApi = new ReservationApiMethodsCls()
}

