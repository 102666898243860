// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class BookingApiMethodsCls {
    async bookingList(wh, {limit, offset, page} = {}) {
      const params = {}
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      console.log('bookingList')
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.list(params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async bookingCreate(wh, data) {
      // Создание брони (Booking)
      console.log('bookingCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bookingRetrieve(wh, id) {
      console.log('bookingRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('core__booking', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'core__booking', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bookingAddPersonPartial(wh, id, data) {
      // data: BookingAddPerson
      // Добавление персоны в бронь
      console.log('bookingAddPersonPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.addPerson.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bookingAddTouristPost(wh, id, data) {
      // data: TouristCreate
      // Создание Tourist для Booking
      console.log('bookingAddTouristPost')
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.addTourist.post(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async bookingBookingApiPartial(wh, id, data) {
      // data: Booking
      console.log('bookingBookingApiPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.core.booking.bookingApi.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async touristUpdate(wh, id, data) {
      // data: Tourist
      // Обновление Tourist
      console.log('touristUpdate')
      const response = await ctx.$services.fetch.request({config: endpoints.core.tourist.update(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async touristDelete(wh, id) {
      // Удаление Tourist
      console.log('touristDelete')
      const response = await ctx.$services.fetch.request({config: endpoints.core.tourist.delete(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.bookingApi = new BookingApiMethodsCls()
}

