// Этот файл сгенерирован автоматически. Не надо его редактировать.

import endpoints from '@/config/apiAutoEndpoints'

export default (ctx) => {
  class AgencyPaymentApiMethodsCls {
    async agencyEntityPaymentsList(wh, id, {limit, offset, page, sort, filters} = {}) {
      // Получение списка платежей для юр. лица
      // filters: created_at, updated_at, owner_entity, operator_identity, state, object_type,
      //          import_agency_payments, article, doc_number, doc_date_after, doc_date_before,
      //          invoice_number, invoice_date_after, invoice_date_before, statement_number,
      //          statement_date_after, statement_date_before, amount_min, amount_max, type, info,
      //          cash, buyer_name, inn, kpp, bik, bank_account, buyer, uuid1c, split_amount, pk,
      //          split
      const params = { ...filters }
      if (limit) params.limit = limit
      if (offset || offset === 0) params.offset = offset
      else if (page) params.offset = (page - 1) * limit
      if (sort) params.o = sort
      console.log('agencyEntityPaymentsList')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.entity.payments.list(id, params)})
      if (ctx) {
        if (limit) {
          return {data:ctx.$services.storage.put_response({data:response.data.results, related_data:response.related_data}, wh), count:response.data.count}
        } else {
          return ctx.$services.storage.put_response(response, wh)
        }
      }
      if (limit) {
          return {data:{data:response.data.results, related_data:response.related_data}, count:response.data.count}
      } else {
        return response.data
      }
    }
    async agencyPaymentCreateCreate(wh, data) {
      // data: AgencyPayment
      // Создание платежа агентству
      console.log('agencyPaymentCreateCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.create.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentImportCreate(wh, data) {
      // data: ImportAgencyPaymentsCreate
      // Импорт файла платежей агентству
      console.log('agencyPaymentImportCreate')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.import.create(), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentImportAcceptPartial(wh, id, data) {
      // data: ImportAgencyPaymentsAccept
      // Принятие платежей агентству
      console.log('agencyPaymentImportAcceptPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.import.accept.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentRetrieve(wh, id) {
      // Получение конкретного платежа агентству
      console.log('agencyPaymentRetrieve')
      if (ctx) {
        const user = ctx.$services.storage.get('finance__agencypayment', id, wh)
        if (user) return user
        ctx.$services.storage.put({data_type:'finance__agencypayment', id:id}, wh)
      }
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.retrieve(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentAcceptPartial(wh, id, data) {
      // data: AgencyPaymentAccept
      // Принять платеж агентству
      console.log('agencyPaymentAcceptPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.accept.partial(id), body: data})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentAnnulPartial(wh, id) {
      // Аннулировать платеж агентству
      console.log('agencyPaymentAnnulPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.annul.partial(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentCancelPartial(wh, id) {
      // Отменить платеж агентству
      console.log('agencyPaymentCancelPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.cancel.partial(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentHoldPartial(wh, id) {
      // Провести платеж агентству
      console.log('agencyPaymentHoldPartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.hold.partial(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
    async agencyPaymentRestorePartial(wh, id) {
      // Восстановить платеж агентству
      console.log('agencyPaymentRestorePartial')
      const response = await ctx.$services.fetch.request({config: endpoints.finance.agency.payment.restore.partial(id)})
      if (ctx) {
        return ctx.$services.storage.put_response(response, wh)
      }
      return response.data
    }
  }

  ctx.$services.agency_paymentApi = new AgencyPaymentApiMethodsCls()
}

